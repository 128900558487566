import Pbf from 'pbf';
import geobuf from 'geobuf';
import base64 from 'base64-arraybuffer';
// 克隆对象
export const cloneObj = function (obj) {
  var o;
  if (typeof obj === 'object') {
    if (obj === null) {
      o = null;
    } else {
      if (obj instanceof Array) {
        o = [];
        for (var i = 0, len = obj.length; i < len; i++) {
          o.push(cloneObj(obj[i]));
        }
      } else {
        o = {};
        for (var j in obj) {
          o[j] = cloneObj(obj[j]);
        }
      }
    }
  } else {
    o = obj;
  }
  return o;
};
// 停止冒泡兼容
export const stopPropagation = function (e) {
  e = window.event || e;
  if (document.all) {
    // 只有ie识别
    e.cancelBubble = true;
  } else {
    e.stopPropagation();
  }
};
/**
 * 通过数组返回sourceData
 * @param isAllwayFeature 总是返回feature
 */
 export const getSourceDataByArray = function (arr, isAllwayFeature) {
  let features = [];
  for (let i = 0, n = arr.length; i < n; i++) {
    let item = arr[i];
    let feature = {
      type: 'Feature',
      properties: item
    }
    let lng = parseFloat(item['lng']);
    let lat = parseFloat(item['lat']);
    if (!isNaN(lng) && !isNaN(lat)) {
      if (lng >= -180 && lng <= 180 && lat >= -90 && lat <= 90) {
        feature.geometry = {
          type: 'Point',
          coordinates: [lng, lat]
        };
      }
    }
    if (!isAllwayFeature && !feature.geometry) {
      continue;
    }
    features.push(feature);
  }
  return {
    type: 'FeatureCollection',
    features: features
  };
};
export const getFeatureByProperties = function (properties) {
  let lng = parseFloat(properties['lng']);
  let lat = parseFloat(properties['lat']);
  if (isNaN(lng) || isNaN(lat)) {
    return null;
  }
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [lng, lat]
    },
    properties: properties
  };
};
// 总是返回feature
export const getFeatureByProperties2 = function (properties) {
  let feature = getFeatureByProperties(properties);
  if (!feature) {
    feature = {
      type: 'Feature',
      properties: properties
    }
  }
  return feature;
}
export const getAoiSourceDataByArray = function (arr) {
  let features = [];
  for (let i = 0, n = arr.length; i < n; i++) {
    let item = arr[i];
    let geom = item.geom;
    if (!geom) {
      // let wkt = item.wkt
    }
    if (typeof geom === 'string') {
      geom = JSON.parse(geom);
    }
    if (!geom) {
      continue;
    }
    let feature = {
      type: 'Feature',
      geometry: geom,
      properties: item
    };
    features.push(feature);
  }
  return {
    type: 'FeatureCollection',
    features: features
  };
};
// "123.45,67.89" ==> [123.45,67.89]，字符串转数组
export const str2coordintes = function (str) {
  let arr = str.split(',');
  let lng = parseFloat(arr[0]);
  let lat = parseFloat(arr[1]);
  return [lng, lat];
};
/**
 * 中国行政区划范围
 */
export const getChinaBounds = () => {
  let screenWidth = window.screen.width
  let bounds = []
  // if (screenWidth > 1920) {
  //   bounds = [[20.312337940586445, -20.117667011232655], [196.94456358914454, 74.7273257812897]]
  // } else if (screenWidth > 1366) {
  //   bounds = [[20.07715669090359, -10.65852322058754], [188.2261661979382, 71.4349780790339]]
  // } else {
  //   bounds = [[54.2206369041536, 1.6018222526570014], [155.7670156142132, 60.65629474821171]]
  // }
  if (bounds.length === 0) {
    bounds = [[71.66, 15.86], [135.05, 54.55]]
  }
  return bounds;
}
/**
 * 限定中国行政区划范围内，根据屏幕分辨率限制
 */
export const getLimitChinaBounds = () => {
  let screenWidth = window.screen.width
  let bounds
  if (screenWidth > 1920) {
    bounds = [[66.286626, 13.224801], [135.468281, 55.599913]]
  }
  //  else if (screenWidth > 1366) {
  //   bounds = [[71.07715669090359, 21.117667011232655], [135.2261661979382, 71.4349780790339]]
  // } else {
  //   bounds = [[71.2206369041536, 21.6018222526570014], [135.7670156142132, 60.65629474821171]]
  // }
  if (!bounds) {
    bounds = [[71.66, 15.86], [135.05, 54.55]]
  }
  return bounds;
}

export const geom2wkt = function (geom) {
  if (typeof geom === "string") {
    geom = JSON.parse(geom);
  }
  let wkx = require("wkx");
  let geometry = wkx.Geometry.parseGeoJSON(geom);
  return geometry.toWkt();
};
export const geom2wkb = function (geom) {
  if (typeof geom === "string") {
    geom = JSON.parse(geom);
  }
  let wkx = require("wkx");
  let geometry = wkx.Geometry.parseGeoJSON(geom);
  return geometry.toWkb();
};

export const wkb2geom = function (str) {
  var Buffer = require("buffer").Buffer;
  let wkx = require("wkx");
  let wkbBuffer = new Buffer(str, "hex");
  let geometry = wkx.Geometry.parse(wkbBuffer);
  return geometry.toGeoJSON();
};
export const wkt2geom = function (str) {
  let wkx = require("wkx");
  let geometry = wkx.Geometry.parse(str);
  return geometry.toGeoJSON();
};
export const any2geom = function (str) {
  let geom = null;
  try {
    geom = JSON.parse(str);
  } catch (e) {
    if (
      str.includes("POINT") ||
      str.includes("LINESTRING") ||
      str.includes("POLYGON")
    ) {
      geom = wkt2geom(str);
    } else {
      geom = wkb2geom(str);
    }
  }
  return geom;
};
function arrayBufferToBase64( buffer ) {
  var binary = '';
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}
function base64ToArrayBuffer(base64) {
  var binary_string =  window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array( len );
  for (var i = 0; i < len; i++)        {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}
export const geobuf2geojson = (str, isBase64) => {
  let data = str;
  if (isBase64) {
    data = base64.decode(data);
  }
  data = base64ToArrayBuffer(data);

  // let str4 = '34 76 10 74 10 72 8 3 18 2 10 10 26 64 226 154 254 110 250 141 130 38 131 1 131 1 167 1 99 133 1 101 165 1 99 171 2 165 1 167 1 99 0 0 165 1 99 167 1 101 142 211 254 110 240 181 130 38 33 33 65 65 67 33 65 65 99 67 99 65 167 1 99 131 1 99 67 67';
  // let arr = str4.split(' ').map(s => parseInt(s))
  // let barr = new Uint8Array(arr);
  
  let pbf = new Pbf(data);
  var geojson = geobuf.decode(pbf);
  return geojson;
  // let Pbf = require('pbf');
  // let geobuf = require('geobuf');

  // let geojson2 = {
  //   type: 'Point',
  //   coordinates: [112.333, 1111]
  // };
  // let buffer = geobuf.encode(geojson2, new Pbf());
  // // console.log(buffer);
  // let data2 = window.btoa(buffer);
  // // console.log(geobuf.decode(new Pbf(buffer)));
  // // console.log(data2);

  // let data = 'W1szNCA3NiAxMCA3NCAxMCA3MiA4IDMgMTggMiAxMCAxMCAyNiA2NCAyMjYgMTU0IDI1NCAxMTAgMjUwIDE0MSAxMzAgMzggMTMxIDEgMTMxIDEgMTY3IDEgOTkgMTMzIDEgMTAxIDE2NSAxIDk5IDE3MSAyIDE2NSAxIDE2NyAxIDk5IDAgMCAxNjUgMSA5OSAxNjcgMSAxMDEgMTQyIDIxMSAyNTQgMTEwIDI0MCAxODEgMTMwIDM4IDMzIDMzIDY1IDY1IDY3IDMzIDY1IDY1IDk5IDY3IDk5IDY1IDE2NyAxIDk5IDEzMSAxIDk5IDY3IDY3XV0=';
  // if (isBase64) {
  //   // console.log(data.toString());
  //   // data = base64.decode(data);
  //   // data = window.atob(data);
  //   // console.log(data);
  //   // data = Buffer.from(data, 'base64');
  //   console.log(data, typeof data);
  // }
  // let pbf = new Pbf(data);
  // console.log(pbf)
  // let geojson = geobuf.decode(pbf);
  // return geojson;
}
