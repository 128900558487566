var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "legends-wrap" }, [
    _vm.title
      ? _c(
          "div",
          { staticClass: "legend-title" },
          [
            _vm._v(" " + _vm._s(_vm.title) + " "),
            _c("i-switch", {
              staticClass: "legend-switch",
              attrs: { size: "small" },
              on: { "on-change": _vm.changeAll },
              model: {
                value: _vm.isShowAll,
                callback: function ($$v) {
                  _vm.isShowAll = $$v
                },
                expression: "isShowAll",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "legends-list" }, [
      _c(
        "ul",
        _vm._l(_vm.legends, function (item, index) {
          return _c(
            "li",
            {
              key: "legend_" + index,
              attrs: { title: _vm.legendTitleObj[index] },
            },
            [
              _c("i", {
                staticClass: "legend-icon",
                style: { "background-color": item.color },
              }),
              _c("span", { staticClass: "legend-name" }, [
                _vm._v(_vm._s(_vm.legendTitleObj[index])),
              ]),
              _c("i-switch", {
                staticClass: "legend-switch",
                attrs: { size: "small", value: _vm.showObj[index] },
                on: {
                  "on-change": function ($event) {
                    return _vm.changeShow(index, $event)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }