import config from '../config.js';
import BaseMap from '../map.js';

import AmapLineLayer from './line.js';
import AmapMarkerLayer from './marker.js';
import AmapPolygonLayer from './polygon.js';
import AmapOverlayer from './overlayer.js';
import AmapHeatmapLayer from './heatmap.js';
import AmapMassMarkerLayer from './massMarker.js';

import AMapLoader from '@amap/amap-jsapi-loader';
import * as turf from '@turf/turf';
import { str2coordintes, getChinaBounds, cloneObj } from '../map.utils.js';
const StandardsKeys = {
  rightclick: 'contextmenu', // 右键
  zoomEnable: 'zoomEnable', // 是否允许放大缩小
  dragEnable: 'dragEnable', // 是否允许拖动
  pitchEnable: 'pitchEnable', // 是否允许设置俯仰角度
  rotateEnable: 'rotateEnable', // 是否可旋转
}
const funs = {
  zoom: 'getZoom', // 获取当前
  pitch: 'getPitch',

}
// 加载
const MapLoader = () => {
  return new Promise((resolve, reject) => {
    if (window.AMap) {
      resolve(window.AMap);
    } else {
      let plugins = [
        'AMap.GeoJSON'
      ]
      let key = config().amap.key;
      AMapLoader.load({
        "key": key,              // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "2.1Beta",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": plugins,           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap)=>{
          window.AMap = AMap;
          resolve(AMap);
      }).catch(e => {
          console.log(e);
      })
      // let src = `https://webapi.amap.com/maps?v=2.0&key=${key}&callback=initAMap`;
      // var script = document.createElement('script');
      // script.type = 'text/javascript';
      // script.async = true;
      // script.src = src;
      // script.onerror = reject;
      // document.head.appendChild(script);
    }
    // window.initAMap = () => {
    //   resolve(window.AMap);
    // }
  })
}
const amapBoundaries2Features = (boundaries) => {
  let list = boundaries || [];
  let features = [];
  for (let i = 0, n = list.length; i < n; i++) {
    let boundaries = list[i];
    let _features = boundaries.map((item) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [item.lng, item.lat],
      },
    }));
    features = features.concat(_features);
  }
  return features;
};
const amapBoundaries2Polygon = (boundaries) => {
  let list = boundaries || [];
  let list2 = [];
  for (let i = 0, n = list.length; i < n; i++) {
    let boundaries = list[i];
    let points = boundaries.map(item => [item.lng, item.lat]);
    list2.push(...points);
  }
  return {
    type: 'Polygon',
    coordinates: [list2]
  };
}


class DTAMap extends BaseMap {
  static MarkerLayer = AmapMarkerLayer;
  static LineLayer = AmapLineLayer;
  static PolygonLayer = AmapPolygonLayer;
  static Overlayer = AmapOverlayer;
  static HeatmapLayer = AmapHeatmapLayer;
  static MassMarkerLayer = AmapMassMarkerLayer;

  AMap = null;
  _isLoaded = false;
  _isLoadedPlugins = false;
  districtSearch = null;
  placeSearch = null;
  mouseTool = null; // 绘制工具
  gl = null;
  geocoder = null; // 编码
  constructor(options) {
    super(options, 'amap');
    this.initMap();
  }
  async initMap() {
    let AMap = await MapLoader();
    this.AMap = AMap;
    let { 
      id,
      level = 12,
      center = '113.26565244761053,23.09412284471854'
    } = this.initMapOptions;
    let options = {
      resizeEnable: false, //是否监控地图容器尺寸变化
      zoom: level,
      center: str2coordintes(center),
      zooms: [4, 20],
    };
    // 底图
    let mapStyle = config().amap.mapStyle;
    if (mapStyle) {
      options.mapStyle = mapStyle;
    }
    let map = new AMap.Map(id, options);
    let plugins = [
      "AMap.ToolBar",
      "AMap.Scale",
      "AMap.MouseTool",
      "AMap.PolyEditor",
      "AMap.DistrictSearch",
      "AMap.ControlBar",
      "AMap.AutoComplete",
      "AMap.PlaceSearch",
      "AMap.HeatMap",
      "AMap.Geocoder",
    ];
    AMap.plugin(plugins, () => {
      this._isLoadedPlugins = true;
      map.addControl(
        new AMap.Scale({
          position: 'RB'
        })
      )
      let mouseTool = new AMap.MouseTool(map);
      this.mouseTool = mouseTool;
      this.mouseTool.on('draw', (e) => {
        this.removeToolMarker();

        let geojson;
        let layer = e.obj;
        let geometry = layer.toGeometry();
        let eventName = '';

        if (layer.className === 'Overlay.Circle') {
          eventName = 'draw.circle';
          let center = layer.getCenter();
          let radius = layer.getRadius();
          center = [center.lng, center.lat];
          let geometry = turf.circle(center, radius / 1000, {
            steps: 256,
            units: 'kilometers'
          });
          geojson = geometry;
          geojson.properties = {
            center: center,
            radius: radius
          };
        } else if (layer.className === 'Overlay.Rectangle') {
          eventName = 'draw.rectangle';
          let bounds = layer.getBounds();
          let southWest = bounds.southWest;
          let northEast = bounds.northEast;
          let points = [
            [southWest.lng, southWest.lat],
            [southWest.lng, northEast.lat],
            [northEast.lng, northEast.lat],
            [northEast.lng, southWest.lat],
            [southWest.lng, southWest.lat],
          ];
          geojson = {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [points]
            }
          }
        } else {
          if (geometry.type === 'Polygon') {
            let coordinates = geometry.coordinates;
            for (let i = 0, n = coordinates.length; i < n; i++) {
              let list = coordinates[i];
              let firstPoint = list[0];
              let lastPoint = list[list.length - 1];
              if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
                list.push(cloneObj(list[0]));
              }
            }
          }
          geojson = {
            type: 'Feature',
            geometry,
          }
        }
        if (!eventName) {
          let eventObj = {
            'Point': 'draw.point',
            'Polygon': 'draw.polygon',
            'LineString': 'draw.line',
          };
          eventName = eventObj[geojson.geometry.type] || 'draw';
        }
        if (!geojson.properties) {
          geojson.properties = {};
        }
        this.fire(eventName, geojson);
      });
      this.geocoder = new AMap.Geocoder({});
      let placeOptions = {
        city: '北京',
        pageSize: 10,
        pageIndex: 1,
      }
      this.placeSearch = new AMap.PlaceSearch(placeOptions);
    })
    this.map = map;
    this.initEvents();
    this.initFuns();
    let canvas = document.getElementById(id).querySelector('canvas');
    let gl = canvas.getContext('webgl');
    this.gl = gl;
  }
  loaded() {
    return new Promise((resolve) => {
      let inter = setInterval(() => {
        if (this._isLoaded) {
          clearInterval(inter);
          resolve();
        }
      }, 10);
    })
  }
  initEvents() {
    this.map.on('complete', e => {
      setTimeout(() => {
        this._isLoaded = true;
        this.fire('loaded');
      })
    });
    this.map.on('zoomend', e=> {
      let zoom = this.map.getZoom();
      this.fire('zoom', zoom);
    });
    this.map.on('moveend', e => {
      let center = this.map.getCenter();
    })
    ['click', 'dblclick', 'rightclick'].forEach(eventName => {
      this.map.on(eventName, e=> {
        if (eventName === 'rightclick') {
          this.removeToolMarker();
        }

        let coordinates = [e.lnglat.lng, e.lnglat.lat];
        let fireName = StandardsKeys[eventName] || eventName;
        this.fire(fireName, coordinates);
      });
    });
    this.map.on('mousemove', e => {
      let lnglat = e.lnglat;
      this.refreshActiveBtnPoisition(e);
      this.fire('mousemove', [lnglat.lng, lnglat.lat]);
    });
  }
  initFuns() {}
  getCenter() {
    let center = this.map.getCenter();
    return [center.lng, center.lat];
  }
  getZoom() {
    return this.map.getZoom();
  }
  getPitch() {
    return this.map.getPitch();
  }
  getRotation() {
    return this.map.getRotation();
  }
  getBounds() {
    let bounds = this.map.getBounds();
    let northEast = bounds.northEast;
    let southWest = bounds.southWest;

    let lnglats = [[northEast.lng, northEast.lat], [southWest.lng, southWest.lat]];
    return lnglats;
  }
  setStatus(options) {
    let statusOptions = {};
    for (let key in options) {
      let val = options[key];
      let setKey = StandardsKeys[key];
      if (setKey) {
        statusOptions[setKey] = !!val;
        this.map.mapStatus[setKey] = !!val;
      }
    }
    this.map.setStatus(statusOptions);
  }
  getStatus(key) {
    let status = this.map.getStatus();
    if (key) {
      return status[key];
    }
    return status;
  }
  resize() {
    this.map && this.map.resize()
  }
  clear() {
    this.map.clearMap();
  }
  destroy() {
    this.map.destroy()
    this.map = null;
    if (this.gl) {
      this.gl.getExtension('WEBGL_lose_context').loseContext();
    }
  }
  addLayer(layer) {
    if (layer.type === 'marker') {
      let markers = layer.markers;
      markers.setMap(this.map);
    } else if (layer.type === 'polygon') {
      let polygons = layer.polygons;
      polygons.setMap(this.map)
      // layer.polygon.addTo(this.map);
    } else if (layer.type === 'line') {
      let lines = layer.lines;
      lines.setMap(this.map);
    } else if (layer.type === 'heatmap') {
      let heatmap = layer.heatmapLayer;
      heatmap.setMap(this.map);
    } else if (layer.type === 'massmarker') {
      let massMarker = layer.massMarker;
      massMarker.setMap(this.map);
    }
  }
  formatGeojson(geojson) {
    if (Array.isArray(geojson)) {
      if (geojson.length === 0) {
        return;
      }
      if (Array.isArray(geojson[0])) {
        geojson = {
          type: 'Polygon',
          coordinates: [geojson]
        }
      } else if (geojson[0].type === 'Feature') {
        geojson = {
          type: 'FeatureCollection',
          features: geojson
        }
      }
    }
    return geojson;
  }
  fitBounds(_geojson, options) {
    let { immediately = false, avoid = [20, 20, 20, 20] } = options || {};
    let AMap = this.AMap;
    if (!_geojson) {
      return;
    }
    let geojson = this.formatGeojson(_geojson);
    if (!geojson) {
      return;
    }
    let bounds = turf.bbox(geojson);
    let AMapBounds = new AMap.Bounds(
      new AMap.LngLat(bounds[0], bounds[1]),
      new AMap.LngLat(bounds[2], bounds[3])
    );
    this.map.setBounds(AMapBounds, immediately, avoid);
  }
  // 限定fitbounds范围
  setLimitBounds(_geojson) {
    let geojson = this.formatGeojson(_geojson);
    let bounds = turf.bbox(geojson);
    let AMap = this.AMap;
    let amapBounds = new AMap.Bounds(
      new AMap.LngLat(bounds[0], bounds[1]),
      new AMap.LngLat(bounds[2], bounds[3])
    );
    this.map.setLimitBounds(amapBounds);
  }
  /**
   * 搜索行政区划
   */
  searchDistrict ({adcode, isAll, subDeep}) {
    let AMap = this.AMap;
    let districtSearch = this.districtSearch;
    let subdistrict = subDeep || 1;
    if (!districtSearch) {
      districtSearch = new AMap.DistrictSearch({
        level: "province",
        extensions: "all",
        subdistrict: subdistrict,
      });
      this.districtSearch = districtSearch;
    }
    this.districtSearch.setSubdistrict(subdistrict);
    return new Promise((resolve) => {
      let searchVal = adcode || "中国";
      if (adcode) {
        districtSearch.setLevel("district");
      }
      districtSearch.search(searchVal, function (status, result) {
        if (status === "complete") {
          let data = result.districtList[0];
          let districtList = data.districtList;
          districtList = districtList.map(item => {
            let center = item.center;
            center = [center.lng, center.lat];
            item.center = center;
            return item;
          });
          if (!isAll) {
            return districtList;
          }
          data.districtList = districtList;
          let center = data.center;
          data.center = [center.lng, center.lat];
          let geometry = amapBoundaries2Polygon(data.boundaries);
          let bounds = turf.bbox(geometry);
          data.bbox = [[bounds[0], bounds[1]], [bounds[2], bounds[3]]];
          if (!adcode) {
            data.bbox = getChinaBounds();
          }
          resolve(data);
        }
      });
    });
  }
  $globalInfoWindow = {};
  inter_close_windowInfo = null;
  /**
   * 地图弹窗
   */
  openInfoWindow(lnglat, content, objs) {
    let AMap = this.AMap;
    let map = this.map;
    let center = lnglat;
    let closeFun = objs && objs.close;
    let autoMove = true;
    let size;
    let offset;
    let anchor;
    let canClose = true;
    let tag = 'normal';
    if (objs) {
      if (objs.autoMove !== undefined) {
        autoMove = objs.autoMove;
      }
      if (objs.size) {
        size = new AMap.Size(objs.size[0], objs.size[1]);
      }
      if (objs.offset !== undefined) {
        offset = objs.offset;
      }
      if (objs.anchor !== undefined) {
        anchor = objs.anchor;
      }
      if (objs.canClose !== undefined) {
        canClose = objs.canClose;
      }
      if (objs.tag !== undefined) {
        tag = objs.tag;
      }
    }
    let infoWindow = this.$globalInfoWindow[tag];
    if (!infoWindow) {
      let options = {
        // isCustom: true,
        content: content, //传入 dom 对象，或者 html 字符串
        // anchor: "right-center",
        autoMove: autoMove,
        closeWhenClickMap: canClose,
      };
      size && (options.size = size);
      offset && (options.offset = offset);
      // anchor && (options.anchor = anchor);
  
      infoWindow = new AMap.InfoWindow(options);
      infoWindow.on("close", () => {
        this.inter_close_windowInfo = setTimeout(() => {
          closeFun && closeFun();
        }, 200);
      });
      this.$globalInfowindow = infoWindow;
    } else {
      infoWindow.setContent(content);
      size && infoWindow.setSize(size);
    }
    if (infoWindow.getIsOpen()) {
      infoWindow.setPosition(center);
    } else {
      infoWindow.open(map, center);
    }
    clearTimeout(this.inter_close_windowInfo);
    infoWindow.reopen = () => {
      infoWindow.open(map, center);
    }
    return infoWindow;
  }
  closeWindowInfo(tag) {
    if (tag) {
      let infoWindow = this.$globalInfoWindow[tag];
      infoWindow && infoWindow.close();
    } else {
      for (let key in this.$globalInfoWindow) {
        let infoWindow = this.$globalInfoWindow[key];
        infoWindow && infoWindow.close();
      }
    }
  }
  setCursor(tag) {
    this.map.setCursor(tag);
  }
  // ******** 工具 ********
  autoComplete = null;
  async initAutoComplete(id, callback) {
    await new Promise((resolve) => {
      let inter = setInterval(() => {
        if (this._isLoadedPlugins) {
          clearInterval(inter);
          resolve();
        }
      }, 10);
    })
    let AMap = this.AMap;
    this.autoComplete = new AMap.AutoComplete({
      input: id,
    });
    this.autoComplete.on("select", (e) => {
      let poi = e.poi;
      let location = poi.location;
      poi.center = [location.lng, location.lat];
      callback(poi);
    });
  }
  setAutoCompleteCitu(city) {
    if (this.autoComplete) {
      this.autoComplete.setCity(city);
    }
  }
  async searchPlace(keyword, opts) {
    if (!this.placeSearch) {
      await new Promise((resolve) => {
        let inter = setInterval(() => {
          if (this.placeSearch) {
            clearInterval(inter);
            resolve();
          }
        }, 10);
      })
    }
    if (opts) {
      if (opts.city) {
        this.placeSearch.setCity(opts.city);
      }
    }
    let res = await new Promise((resolve) => {
      this.placeSearch.search(keyword, (statue, result, error) => {
        resolve(result);
      })
    })
    return res;
  }
  // ******** 绘制 ********
  // 画点
  drawPoint() {
    let mouseTool = this.mouseTool;
    let content = '<span></span>'
    mouseTool.marker({
      content
    });
    this.addToolMarker('point');
  }
  // 画线
  drawLine() {
    let mouseTool = this.mouseTool;
    mouseTool.polyline({
      strokeColor: "#75F5DA",
      strokeWeight: 4,
      cursor: "pointer",
      strokeOpacity: 1,
      strokeStyle: "solid",
    });
  }
  // 画多边形
  drawPolygon() {
    let mouseTool = this.mouseTool;
    mouseTool.polygon({
      fillColor: "#00b0ff",
      strokeColor: "#80d8ff",
    });
    this.addToolMarker('polygon')
  }
  // 画矩形
  drawRectagel() {
    let mouseTool = this.mouseTool;
    mouseTool.rectangle({
      fillColor: "#00b0ff",
      strokeColor: "#80d8ff",
    });
    this.addToolMarker('rectangle');
  }
  // 画圆
  drawCircle() {
    let mouseTool = this.mouseTool;
    mouseTool.circle({
      strokeColor: "#75F5DA",
      strokeWeight: 4,
      strokeOpacity: 1,
      strokeStyle: "solid",
      cursor: "pointer",
    });
    this.addToolMarker('circle');
  }
  closeDraw(tag) {
    let mouseTool = this.mouseTool;
    mouseTool.close(tag);
  }
  toolBtnMarker = null;
  mapToolPosition = {};
  addToolMarker(tag) {
    let AMap = this.AMap;
    let icons = {
      point: "/images/marker/point.png",
      circle: "/images/marker/circle.png",
      rectangle: "/images/marker/rectangular.png",
      polygon: "/images/marker/polygon.png"
    };
    if (this.toolBtnMarker) {
      this.map.removeLayer([this.toolBtnMarker]);
    }
    let curIcon = new AMap.Icon({
      size: new AMap.Size(24, 24),
      image: icons[tag],
      imageSize: new AMap.Size(24, 24),
      imageOffset: new AMap.Pixel(0, 0),
    });
    this.toolBtnMarker = new AMap.Marker({
      position: this.mapToolPosition.lnglat,
      icon: curIcon,
      cursor: "none",
      offset: new AMap.Pixel(-12, -10),
    });
    this.toolBtnMarker.setLabel({
      offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
      content: "<div class='info'>点击左键开始绘制，右键取消绘制</div>", //设置文本标注内容
      direction: "right", //设置文本标注方位
    });
    this.map.add(this.toolBtnMarker);
  }
  removeToolMarker() {
    if (this.toolBtnMarker) {
      // this.map.removeLayer([this.toolBtnMarker]);
      this.toolBtnMarker.remove();
      this.toolBtnMarker = null;
    }
    this.closeDraw();
  }
  // 根据点位获取地址
  async getAddress(lnglat) {
    if (!this.geocoder) {
      await new Promise((resolve) => {
        let inter = setInterval(() => {
          if (this.geocoder) {
            clearInterval(inter);
            resolve();
          }
        })
      })
    }
    let res = await new Promise((resolve) => {
      this.geocoder.getAddress(lnglat, (state, result) => {
        if (state === 'complete' && result.info === 'OK') {
          resolve(result.regeocode);
        }
      })
    })
    return res;
  }
  // 更新工具按钮位置
  refreshActiveBtnPoisition(p) {
    this.mapToolPosition = p;
    if (this.toolBtnMarker) {
      this.toolBtnMarker.setPosition(p.lnglat);
    }
  }
  clearGraphics(overlays) {
    let map = this.map;
    map.remove(overlays);
  }

  // 将geometry转化为高德用的path
  convertGeometry2Path(geometry) {
    let LngLat = this.AMap.LngLat;
    let list = [];
    let coordinates = geometry.coordinates;
    if (geometry.type === 'Polygon') {
      for (let i = 0, n = coordinates.length; i < n; i++) {
        let lnglats = coordinates[i];
        let list2 = lnglats.map(coord => {
          return new LngLat(coord[0], coord[1]);
        });
        list.push(list2);
      }
    } else if (geometry.type === 'MultiPolygon'){
      for (let group of coordinates) {
        let list3 = [];
        for (let i = 0, n = group.length; i < n; i++) {
          let lnglats = group[i];
          let list2 = lnglats.map(coord => {
            return new LngLat(coord[0], coord[1]);
          });
          list3.push(list2);
        }
        list.push(list3);
      }
    }
    return list;
  }
}



export default DTAMap;
