import Vue from 'vue';
export default () => {
  return {
    mapType: 'amap',
    amap: {
      key: Vue.envConfig.mapConfig.amapKey,
      mapStyle: Vue.envConfig.mapConfig.amapStyle
    }
  };
}
