import { eventMixin } from './mixins';
class BaseMap {
  map = null;
  mapType = '';
  initMapOptions = {};
  mapStatus = {};
  constructor(options, type) {
    this.mapType = type;
    this.initMapOptions = options || {};
    Object.assign(this, eventMixin);
  }
  initMap() {
    console.log('dtmap initMap');
  }
  setStatus(options) {
    this.mapStatus = options;
  }
  getZoom() {}
  getPitch() {}
  getRotation() {}
  addLayer() {}
  removeLayer() {}
  resize() {}
  fitBounds(geojson) {}
  
}
export default BaseMap;
