import { eventMixin } from './mixins';
class Layer {
  id = '';    // layer的id
  type = '';  // 类型
  title = ''; // 标题
  display = true; // 是否显示
  uniformConfig = {};
  heatmapConfig = {};
  layerStyle = {};
  featuresMap = null;
  _featureTagMax = 0;
  
  constructor() {
    Object.assign(this, eventMixin);
    this.featuresMap = new Map();
  }
  show(){
    this.display = true;
  }
  hide(){
    this.display = false;
  }
  setStyle(style) {
    this.layerStyle = style;
  }
  remove() {}
  getNewId(isSingle) {
    this._featureTagMax += 1;
    let linkStr = isSingle ? '' : '_'
    let layerId = this.id || '';
    return `feature_${layerId}_${this._featureTagMax}${linkStr}`;
  }
  setFeatures(features = []) {
    let featureTag = this.getNewId();
    for (let i = 0, n = features.length; i < n; i++) {
      let feature = features[i];
      if (!feature) {
        continue;
      }
      let featureId = feature.feature_id || featureTag + i;
      feature.feature_id = featureId;
      if (!feature.properties) {
        feature.properties = {};
      }
      this.featuresMap.set(featureId, feature);
    }
    return features;
  }
  getFeatures() {
    return this.featuresMap.values();
  }
  getFeature(featureId) {
    let value = this.featuresMap.get(featureId);
    return value;
  }
  removeFeature(id) {
    if (this.featuresMap.has(id)) {
      this.featuresMap.delete(id);
    }
  }
  clearFeatures() {
    this.featuresMap.clear();
  }
}
export class MarkerLayer extends Layer {
  constructor() {
    super();
    this.type = 'marker';
    this.uniformConfig = {
      icon_id: null,
      icon_url: '',
      color: 'f86767',
      size: '',
      symbol: '',
      bubble: false,
      is_cluster: false,
    }
  }
}
export class LineLayer extends Layer {
  lineFeature = null;
  constructor() {
    super();
    this.type = 'line';
  }
}
export class PolygonLayer extends Layer {
  polygonFeature = null;
  constructor() {
    super();
    this.type = 'polygon';
  }
}
export class HeatmapLayer extends Layer {
  constructor() {
    super();
    this.type = 'heatmap';
  }
}
export class MassMarker extends Layer {
  constructor() {
    super();
    this.type = 'massmarker';
  }
}
export class Overlayer {
  featureMap = null;
  constructor() {
    this.featureMap = new Map();
  }
  addOverlay(layer) {
    let id = layer.id;
    this.featureMap.set(id, layer);
  }
  removeOverlay (layer) {
    let id = layer.id;
    this.featureMap.delete(id);
  }

  getHashId(layerId, type) {
    let hashId = '';
    switch(type) {
      case 'marker':
        hashId = 'm_' + layerId;
        break;
      case 'line':
        hashId = 'l_' + layerId;
        break;
      case 'polygon':
        hashId = 'p_' + layerId;
        break;
    }
    return hashId;
  }
  addLayer(dtLayer) {
    let type = dtLayer.type;
    let layerId = dtLayer.id;
    let hashId = this.getHashId(layerId, type);
    this.featureMap.set(hashId, dtLayer);
  }
  removeLayer(dtLayer) {
    let type = dtLayer.type;
    let layerId = dtLayer.id;
    let hashId = this.getHashId(layerId, type);
    this.featureMap.delete(hashId);
  }
  getLayer(layerId, type) {
    let hashId = this.getHashId(layerId, type);
    return this.featureMap.get(hashId);
  }
  getLayers(type) {
    let list = [];
    let layers = this.featureMap.values();
    if (type) {
      for (let i = 0, n = layers.length; i < n; i++) {
        let layer = layers[i];
        if (layer.type === type) {
          list.push(layer);
        }
      }
    } else {
      list = layers;
    }
    return list;
  }
  clearLayers(type) {
    let layers = this.getLayers(type);
    for (let i = 0, n = layers.length; i < n; i++) {
      let layer = layers[i];
      this.removeLayer(layer);
    }
  }
  getLayerIds(type) {
    let layers = this.getLayers(type);
    return layers.map(layer => layer.id);
  }
  getLayersCount(type) {
    let layers = this.getLayers(type);
    return layers.length;
  }
  show() {

  }
  hide() {

  }

}
window.Layer = Layer;
window.MarkerLayer = MarkerLayer;
