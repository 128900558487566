var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.doBack,
          expression: "doBack",
        },
      ],
      staticClass: "city-wrap modal-bg",
    },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _vm._v(" 当前城市："),
          _c("span", { staticClass: "city-name" }, [
            _vm._v(_vm._s(_vm.curCity)),
          ]),
          _c("Icon", {
            attrs: { type: "md-close" },
            nativeOn: {
              click: function ($event) {
                return _vm.doBack.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "AutoComplete",
        {
          staticClass: "city-search",
          attrs: { placeholder: "输入城市名", icon: "ios-search" },
          on: {
            "on-change": _vm.handleSearch,
            "on-select": _vm.handleSelectSearch,
          },
          model: {
            value: _vm.searchValue,
            callback: function ($$v) {
              _vm.searchValue = $$v
            },
            expression: "searchValue",
          },
        },
        _vm._l(_vm.searchData, function (city, index) {
          return _c(
            "Option",
            { key: "search_result_" + index, attrs: { value: city.cname } },
            [
              _vm._v(
                " " +
                  _vm._s(city.cname) +
                  "(" +
                  _vm._s(_vm._getPyname(city.pyname)) +
                  ") "
              ),
            ]
          )
        }),
        1
      ),
      _c("div", { staticClass: "city-hot-cities" }, [
        _c(
          "ul",
          _vm._l(_vm.hotCitys, function (city, index) {
            return _c(
              "li",
              {
                key: "hot_city_" + index,
                on: {
                  click: function ($event) {
                    return _vm.handleSelect(city)
                  },
                },
              },
              [_vm._v(" " + _vm._s(city.name) + " ")]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "city-letters" }, [
        _c(
          "ul",
          _vm._l(_vm.groupCities, function (group, index) {
            return _c(
              "li",
              {
                key: "letter_" + index,
                class: { cur: _vm.selectLetter === group.letter },
                on: {
                  click: function ($event) {
                    return _vm.scroll2Letter(group.letter)
                  },
                },
              },
              [_vm._v(" " + _vm._s(group.letter) + " ")]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { ref: "cityGroup", staticClass: "city-group" },
        _vm._l(_vm.groupCities, function (group, gIndex) {
          return _c(
            "dl",
            {
              key: "group_" + gIndex,
              class: { "dl-line1": _vm.checkList1Line(group.list) },
            },
            [
              _c("dt", { class: "city-group-" + group.letter }, [
                _vm._v(_vm._s(group.letter)),
              ]),
              _c("dd", [
                _c(
                  "ul",
                  _vm._l(group.list, function (city, index) {
                    return _c(
                      "li",
                      {
                        key: "city_" + gIndex + "_" + index,
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(city)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(city.cname) + " ")]
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }