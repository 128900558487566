<template>
  <div class="city-wrap modal-bg" v-click-outside="doBack">
    <div class="title">
      当前城市：<span class="city-name">{{ curCity }}</span
      ><Icon type="md-close" @click.native="doBack" />
    </div>
    <AutoComplete
      class="city-search"
      placeholder="输入城市名"
      icon="ios-search"
      v-model="searchValue"
      @on-change="handleSearch"
      @on-select="handleSelectSearch"
    >
      <Option
        v-for="(city, index) in searchData"
        :key="'search_result_' + index"
        :value="city.cname"
      >
        {{ city.cname }}({{ _getPyname(city.pyname) }})
      </Option>
    </AutoComplete>
    <div class="city-hot-cities">
      <ul>
        <li
          v-for="(city, index) in hotCitys"
          :key="'hot_city_' + index"
          @click="handleSelect(city)"
        >
          {{ city.name }}
        </li>
      </ul>
    </div>
    <div class="city-letters">
      <ul>
        <li
          v-for="(group, index) in groupCities"
          :key="'letter_' + index"
          :class="{ cur: selectLetter === group.letter }"
          @click="scroll2Letter(group.letter)"
        >
          {{ group.letter }}
        </li>
      </ul>
    </div>
    <div class="city-group" ref="cityGroup">
      <dl
        v-for="(group, gIndex) in groupCities"
        :key="'group_' + gIndex"
        :class="{ 'dl-line1': checkList1Line(group.list) }"
      >
        <dt :class="'city-group-' + group.letter">{{ group.letter }}</dt>
        <dd>
          <ul>
            <li
              v-for="(city, index) in group.list"
              :key="'city_' + gIndex + '_' + index"
              @click="handleSelect(city)"
            >
              {{ city.cname }}
            </li>
          </ul>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import Citys from "./citys";
import { hot, allLetters } from "./citys";
import { getFile } from "@/service";
let allCities = [];
// 显示的城市
const showCitys = [
  // "上海市",
  // "东莞市",
  // "佛山市",
  // "北京市",
  // "南京市",
  // "厦门市",
  // "合肥市",
  // "天津市",
  // "宁波市",
  // "广州市",
  // "成都市",
  // "杭州市",
  // "武汉市",
  // "深圳市",
  // "苏州市",
  // "西安市",
  // "郑州市",
  // "重庆市",
  // "长沙市",
  // "青岛市",
];
const ignoreCitys = ["香港", "澳门", "台湾"];
export default {
  props: ["curCity"],
  data() {
    return {
      selectLetter: "",
      hotCitys: [],
      groupCities: [],
      scrollTop: 0,
      searchValue: "",
      searchData: [],
    };
  },
  computed: {
    allLetters() {
      return allLetters;
    },
  },
  watch: {
    searchValue(newval) {
      if (!newval) {
        this.searchData = [];
      }
    },
  },
  methods: {
    initData() {
      let _hotCitys = [];
      for (let i = 0, n = hot.length; i < n; i++) {
        let code = hot[i];
        let city = this._getCity(code);
        if (!this.checkCityShow(city.cname)) {
          continue;
        }
        city.name = city.cname.replace("市", "");
        _hotCitys.push(city);
      }
      this.hotCitys = _hotCitys;
      let _allCities = [];
      let groupObj = {};
      for (let i = 0, n = allLetters.length; i < n; i++) {
        let letter = allLetters[i];
        groupObj[letter] = [];
      }

      for (let key in Citys["86"]) {
        if (Citys[key]) {
          for (let key2 in Citys[key]) {
            let city = Citys[key][key2];
            if (!this.checkCityShow(city.cname)) {
              continue;
            }
            city.adcode = key2;
            _allCities.push(city);
            let firstChart = city.pyname.substr(0, 1);
            groupObj[firstChart].push(city);
          }
        } else {
          let city = Citys["86"][key];
          if (!this.checkCityShow(city.cname)) {
            continue;
          }
          city.adcode = key;
          _allCities.push(city);
          let firstChart = city.pyname.substr(0, 1);
          groupObj[firstChart].push(city);
        }
      }
      allCities = _allCities;
      let _groupCities = [];
      for (let i = 0, n = allLetters.length; i < n; i++) {
        let letter = allLetters[i];
        let list = groupObj[letter] || [];
        if (list.length === 0) {
          continue;
        }
        // list = list.sort((a, b) => (a.pyname > b.pyname ? 1 : -1));
        _groupCities.push({
          letter: letter,
          list: list,
        });
      }
      this.groupCities = _groupCities;
      // this.checkDBCitys(allCities);
    },
    // 对比现有城市与数据库中的城市
    async checkDBCitys(allCities) {
      let txtFile = "/dbcitys.txt";
      let txt = await getFile(txtFile);
      if (!txt) {
        return;
      }
      let arr = txt.split("\n");
      let citys2 = [];
      for (let i = 0, n = arr.length; i < n; i++) {
        let str = arr[i].trim();
        if (!str) {
          continue;
        }
        let arr2 = str.split("\t");
        citys2.push({
          adcode: arr2[0].trim(),
          cname: arr2[1].trim(),
        });
      }
      let obj = {};
      for (let i = 0, n = allCities.length; i < n; i++) {
        let item = allCities[i];
        obj[item.cname] = item;
      }
      let removes = [];
      let adds = [];
      let changes = [];
      let obj2 = [];
      for (let i = 0, n = citys2.length; i < n; i++) {
        let city = citys2[i];
        obj2[city.cname] = city;
        if (obj[city.cname]) {
          if (obj[city.cname].adcode === city.adcode) {
            continue;
          }
          changes.push(
            `${city.cname}, ${obj[city.cname].adcode} => ${city.adcode}`
          );
          continue;
        }
        adds.push(`${city.cname}, ${city.adcode}`);
      }
      for (let i = 0, n = allCities.length; i < n; i++) {
        let city = allCities[i];
        if (!obj2[city.cname]) {
          removes.push(`${city.cname}, ${city.adcode}`);
        }
      }
      /* eslint-disable */
      console.log("adds", adds);
      console.log("removes", removes);
      console.log("changes", changes);
      /* eslint-enable */
    },
    checkCityShow(cname) {
      if (showCitys && showCitys.length > 0) {
        return showCitys.includes(cname);
      }
      if (ignoreCitys && ignoreCitys.length > 0) {
        return !ignoreCitys.includes(cname);
      }
      return true;
    },
    // 滚动到相应的字母位置
    scroll2Letter(letter) {
      this.selectLetter = letter;
      let cityGroup = this.$refs.cityGroup;
      let dom = document.querySelector(".city-group-" + letter);
      let oldTop = cityGroup.scrollTop;
      let newTop = dom.offsetTop;
      let allLength = newTop - oldTop;
      let symbol = 1;
      if (newTop - oldTop < 0) {
        allLength = -1 * allLength;
        symbol = -1;
      }
      let num = allLength / 30;
      let inter = setInterval(() => {
        oldTop += symbol * num;
        if (
          (symbol > 0 && oldTop >= newTop) ||
          (symbol < 0 && oldTop <= newTop)
        ) {
          clearInterval(inter);
          oldTop = newTop;
        }
        cityGroup.scrollTop = oldTop;
      }, 10);
      // cityGroup.scrollTop = dom.offsetTop;
    },
    handleSelect(city) {
      this.$emit("select", city);
    },
    _getCity(code) {
      if (Citys["86"][code]) return Citys["86"][code];
      let provCode = code.substring(0, 2).padEnd(6, "0");
      return Citys[provCode][code];
    },
    _getPyname(name) {
      return name.replace(/ /g, "");
    },
    handleSearch(val) {
      if (!val) {
        this.searchData = [];
        return;
      }
      let newval = this._getPyname(val).toLowerCase();
      let data = allCities.filter((item) => {
        if (item.cname.includes(val) || item.pyname.includes(val)) {
          return true;
        }
        let pyname = this._getPyname(item.pyname);
        pyname = pyname.toLowerCase();
        if (pyname.includes(newval)) {
          return true;
        }
        return false;
      });
      this.searchData = data;
    },
    handleSelectSearch(name) {
      let city;
      for (let i = 0, n = this.searchData.length; i < n; i++) {
        let item = this.searchData[i];
        if (item.cname === name) {
          city = item;
          break;
        }
      }
      this.$nextTick(() => {
        this.handleSelect(city);
      });
    },
    checkList1Line(list) {
      let lenList = list.map((item) => item.cname.length * 12 + 8);
      let allLength = lenList.reduce((prev, cur) => {
        return prev + cur;
      });
      return allLength <= 326;
    },
    doBack() {
      this.$emit("close");
    },
  },
  mounted() {
    this.initData();
  },
  beforeDestroy() {},
};
</script>

<style lang="less">
@import "~style/config.less";
@cityColor: #fff;
@titleColor: rgba(255, 255, 255, 0.6);

.city-wrap {
  // background: #1e2e49;
  .title {
    position: relative;
    padding: 12px;
    font-size: 14px;
    color: @titleColor;
    border-bottom: 1px solid @modalSplitLineColor;
    .city-name {
      color: @cityColor;
    }
    .ivu-icon {
      float: right;
    }
  }
  ul li {
    display: inline-block;
    margin: 2px 5px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: #3278ff;
    }
  }
  .city-search,
  .city-hot-cities,
  .city-letters {
    padding: 0 12px;
  }
  .city-search {
    height: 32px;
    line-height: 32px;
    margin-top: 12px;
    .ivu-input {
      background: rgba(51, 65, 86, 0.6);
      border-radius: 2px;
      border: 1px solid rgba(56, 76, 108, 0.8);
      color: @cityColor;
      &:placeholder {
        color: #7a838b;
      }
    }
    .ivu-icon {
      color: rgba(255, 255, 255, 0.5);
      background: rgba(33, 45, 63, 0.52);
      border-radius: 1px;
      border: 1px solid rgba(56, 76, 108, 0.8);
    }
    .ivu-select-dropdown {
      width: 250px;
      min-width: 250px !important;
      height: 300px;
      .ivu-select-dropdown-list {
        li,
        option {
          display: block;
          color: @fontColor;
          font-size: 12px;
          line-height: 30px;
          padding: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            color: @cityColor;
            background: rgba(90, 108, 128, 0.15);
          }
          &.ivu-select-item-focus {
            background: rgba(90, 108, 128, 0.15);
          }
        }
      }
    }
  }
  .city-hot-cities,
  .city-letters {
    li {
      color: @cityColor;
    }
  }
  .city-hot-cities {
    margin-top: 16px;
  }
  .city-letters {
    margin-top: 12px;
    position: relative;
    &:after {
      position: absolute;
      top: calc(100% + 10px);
      content: "";
      width: calc(100% - 40px);
      height: 1px;
      background: @modalSplitLineColor;
    }
    ul li.cur {
      color: #3278ff;
    }
  }
  .city-group {
    width: 100%;
    height: 240px;
    max-height: calc(100vh - 350px);
    padding: 9px 0;
    margin-top: 12px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    transition: all 0.3s;

    dl {
      margin: 9px 0;
      dt,
      dd {
        display: inline-block;
        vertical-align: top;
      }
      dt {
        width: 48px;
        font-size: 24px;
        text-align: center;
        color: rgba(255, 255, 255, 0.2);
      }
      dd {
        width: calc(100% - 48px);
        color: @fontColor;
      }
      &.dl-line1 {
        dt,
        dd {
          vertical-align: middle;
        }
      }
    }
  }
}

@cityColor: #333;
@baseColor: #000;
@titleColor: fade(@baseColor, 60%);
.white-style {
  .city-wrap {
    .city-search {
      .ivu-input {
        background: transparent;
        border: 1px solid rgba(56, 76, 108, 0.8);
      }
      .ivu-icon {
        color: #333;
        background-color: #fff;
      }
      .ivu-select-dropdown {
        .ivu-select-dropdown-list {
          li {
            &:hover,
            &.ivu-select-item-focus {
              background-color: #ccc;
            }
          }
        }
      }
    }
    .city-group {
      dl dt {
        color: rgba(33, 45, 63, 0.52);
      }
    }
  }
}
</style>
