export const eventMixin = {
  on(eventName, cb) {
    if (!this.events) {
      this.events = {};
    }
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(cb);
    return this;
  },
  off (eventName, cb) {
    if (!this.events[eventName]) {
      return;
    }
    if (!cb) {
      this.events[eventName] = null;
    } else {
      this.events[eventName].some((fn, i) => {
        if (cb === fn) {
          this.events[eventName].splice(i, 1);
          return true;
        }
      })
    }
  },
  fire(eventName, ...args) {
    let funs = this.events && this.events[eventName];
    if (!funs) {
      return;
    }
    funs.forEach(cb => cb.apply(this, args));
  }
}
