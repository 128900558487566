const StandardsKeys = {
  rightclick: 'contextmenu', // 右键
}
export const layerMixin = Base => class extends Base{
  bindLayerEvents(marker) {
    let getFeature = this.getFeature.bind(this);
    let type = this.type;
    ['click', 'dblclick', 'rightclick'].forEach(eventName => {
      marker.on(eventName, e => {
        let extData = {};
        if (type === 'massmarker') {
          extData = e.data;
        } else {
          extData = e.target.getExtData();
        }
        let feature_id = extData.feature_id;
        let feature = getFeature(feature_id);

        let fireName = StandardsKeys[eventName] || eventName;
        this.fire(fireName, { feature });
      })
    });
  }
}
