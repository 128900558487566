import {PolygonLayer} from '../layer';
import {cloneObj} from '../map.utils.js';
import {layerMixin} from './layer.js';
class AmapPolygonLayer extends layerMixin(PolygonLayer) {
  polygons = null;
  AMap = null;
  map = null;
  constructor(opts) {
    super()
    let AMap = window.AMap;
    this.AMap = AMap;

    let id = opts.id || '';
    this.id = id;
    this.title = opts.title || '';

    let style = {
      strokeColor: opts.strokeColor || '#3519FB',
      strokeOpacity: opts.strokeOpacity || 0.4,
      strokeWeight: opts.strokeWeight || 1.6,
      strokeStyle: opts.strokeStyle || 'solid',
      fillColor: opts.fillColor || '#3519FB',
      fillOpacity: opts.fillOpacity || 0.06,
    };
    super.setStyle(style);
    this.setFeatures();
  }
  setFeatures(sourceData) {
    let AMap = this.AMap;
    let features = sourceData && sourceData.features || [];
    features = super.setFeatures(features);
    let bindLayerEvents = this.bindLayerEvents.bind(this);

    features = features.map((feature, index) => {
      return {
        type: feature.type,
        geometry: feature.geometry,
        properties: {
          feature_id: feature.feature_id
        },
      }
    })
    let newSourceData = {
      type: 'FeatureCollection',
      features
    };
    if (!this.polygons) {
      this.polygons = new AMap.GeoJSON({
        geoJSON: newSourceData,
        getPolygon: (geojson, lnglats) => {
          let style = cloneObj(this.layerStyle);
          style.path = lnglats;
          style.extData = {
            feature_id: geojson.properties.feature_id
          };
          let polygon = new AMap.Polygon(style);
          bindLayerEvents(polygon);
          return polygon;
        }
      });
    } else {
      this.polygons.importData(newSourceData);
    }
  }
  addTo(map) {
    this.map = map;
    this.polygons.addTo(map);
  }
  setMap(map) {
    this.map = map;
    if (this.polygons) {
      this.polygons.setMap(map);
    }
  }
  show() {
    this.polygons.show();
    super.show();
  }
  hide() {
    this.polygons.hide();
    super.hide();
  }
}
export default AmapPolygonLayer;
