import {MarkerLayer} from '../layer.js';
import {cloneObj} from '../map.utils.js';
import {layerMixin} from './layer.js';
const StandardsKeys = {
  rightclick: 'contextmenu', // 右键
}
class AmapMarkerLayer extends layerMixin(MarkerLayer) {
  AMap = null;
  map = null;
  marker = null;
  markers = null;
  contentFun = null;
  constructor(opts = {}) {
    super();
    let AMap = window.AMap;
    this.AMap = AMap;

    let id = opts.id || '';
    this.id = id;
    this.title = opts.title || '';
    this.contentFun = opts.contentFun || null;
    let style = {
      anchor: opts.anchor || 'bottom-center',
      size: opts.size,
      offset: opts.offset,
      icon: opts.icon,
      iconSize: opts.iconSize
    }
    super.setStyle(style);
    this.setFeatures();
  }
  setFeatures(sourceData) {
    let AMap = this.AMap;
    let features = [];
    if (sourceData) {
      if (Array.isArray(sourceData)) {
        features = sourceData;
      } else if (sourceData.type === 'FeatureCollection') {
        features = sourceData.features || [];
      }
    }
    features = super.setFeatures(features);
    let contentFun = this.contentFun;
    let getFeature = this.getFeature.bind(this);
    let bindLayerEvents = this.bindLayerEvents.bind(this);
    features = features.map((feature) => ({
      type: feature.type,
      geometry: feature.geometry,
      properties: {
        feature_id: feature.feature_id,
        title: feature.properties.title,
      }
    }));
    let newSourceData = {
      type: 'FeatureCollection',
      features
    };
    if (!this.markers) {
      this.markers = new this.AMap.GeoJSON({
        geoJSON: newSourceData,
        getMarker: (geojson, lnglats) => {
          if (!geojson) {
            return null;
          }
          let style = cloneObj(this.layerStyle);
          let center = geojson.geometry.coordinates;
          let properties = geojson.properties;
          let feature_id = properties.feature_id;
          let lnglat = new AMap.LngLat(center[0], center[1]);
          let icon = new AMap.Icon({
            size: style.size,
            imageOffset: style.offset,
            image: style.icon,
            imageSize: style.iconSize || style.size,
          });
          let options = {
            position: lnglat,
            icon: icon,
            title: properties.title,
            anchor: style.anchor,
            extData: {
              feature_id: feature_id
            }
          }
          if (contentFun) {
            let feature = getFeature(feature_id);
            let content = contentFun(feature);
            options.content = content || '';
          }
          let marker = new AMap.Marker(options);
          
          bindLayerEvents(marker);
          return marker;
        }
      });
    } else {
      this.markers.importData(newSourceData);
    }
  }
  addTo(map) {
    this.map = map;
    this.markers.addTo(map);
  }
  setMap(map) {
    this.map = map;
    if (this.markers) {
      this.markers.setMap(map);
    }
  }
  show() {
    this.markers.show();
    super.show();
  }
  hide() {
    this.markers.hide();
    super.hide();
  }
}
export default AmapMarkerLayer;
