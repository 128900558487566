<template>
  <div class="map-panel" :id="mapId" :ref="mapId">
    <slot></slot>
    <div class="map-tools">
      <div
        v-if="deep && deep !== 1"
        class="map-tools-btn back-btn"
        @click="handleBackClick"
      >
        <img src="/images/back-btn.png" alt="" />
        返回上一级
      </div>
      <CityBtn
        theme="white"
        class="map-tools-btn"
        :cityName="cityName"
        @select="selectCityName"
        v-if="isSelectCity"
      ></CityBtn>
      <div
        class="map-tools-btn map-draw-tools"
        slot="mapTools"
        v-if="isShowToolBar"
      >
        <div class="tool-bar-search" v-if="isShowSearchAddress">
          <Select
            class="tool-bar-search-autocomplete"
            ref="poiSelect"
            clearable
            filterable
            :remote-method="handleSearchPoi"
            :loading="searchPoiLoading"
            @on-select="handleSelectSearchPoi"
          >
            <Input
              slot="input"
              v-model="searchPoiKey"
              placeholder="请输入地址"
              clearable
              @on-enter="handleEnterInput"
              @on-clear="handleSearchClear"
            >
              <Icon
                class="tool-bar-search-icon"
                custom="icon-map-search"
                slot="prefix"
              ></Icon>
            </Input>
            <Option
              v-for="(item, index) in poiList"
              :key="'poi_' + index"
              :value="item.name"
              >{{ item.name }}</Option
            >
          </Select>
        </div>

        <div class="tool-bar-btns">
          <Tooltip
            class="tool-bar-icon"
            v-for="(item, index) in mapToolBars"
            :key="'toolbar_' + index"
            :content="item.title"
            @click.native="item.click"
          >
            <span class="map-draw-btn" :class="item.icon"></span>
          </Tooltip>
        </div>
      </div>
      <slot name="mapTools"></slot>
    </div>
    <MapLegends
      :legendsObj="legendsObj"
      :legendsNums="legendsNums"
      :isShowNum="true"
      v-if="isLegendsShow"
      @changeShow="changeLegendShow"
    ></MapLegends>
  </div>
</template>

<script>
import CityBtn from "@/components/city/CityBtn.vue";
import DTMap from "js/map/index.js";
import MapLegends from "./MapLegends.vue";
import { getChinaBounds, getLimitChinaBounds } from "js/map/map.utils.js";
import { handlePngAsync } from "js/utils.js";
import { GetDetailBrowsingRecord } from "@/service";
import "style/map.less";
import * as turf from "@turf/turf";

export default {
  props: [
    "id",
    "isSelectCity",
    "mapTools",
    "isCity",
    "legendsObj",
    "ignoreFitChina",
    "isWatchLocation",
    "deep",
  ],
  data() {
    return {
      mapId: "",
      map: null,
      cityName: "",
      isLoaded: false,
      level: 9,
      zoom: 9,
      center: "116.309834, 40.042785",
      layerMap: new Map(),
      // 图例相关
      legendsLayerIds: [],
      legendsNums: null,
      searchPoiKey: "",
      poiList: [],
      searchPoiLoading: false,
    };
  },
  computed: {
    isShowToolBar() {
      return this.mapTools && this.mapTools.length > 0;
    },
    isShowSearchAddress() {
      return this.mapTools && this.mapTools.includes("searchAddress");
    },
    mapToolBars() {
      let mapTools = this.mapTools || [];
      let list = [
        {
          title: "选点",
          icon: "icon-draw-point",
          key: "drawPoint",
          click: () => {
            this.$emit("startDraw");
            if (this.map) {
              this.map.closeDraw(true);
              this.map.drawPoint();
            }
          },
        },
        {
          title: "画圆查询",
          icon: "icon-draw-circle",
          key: "drawCircle",
          click: () => {
            this.$emit("startDraw");
            if (this.map) {
              this.map.closeDraw(true);
              this.map.drawCircle();
            }
          },
        },
        {
          title: "矩形查询",
          icon: "icon-draw-rect",
          key: "drawRect",
          click: () => {
            this.$emit("startDraw");
            if (this.map) {
              this.map.closeDraw(true);
              this.map.drawRectagel();
            }
          },
        },
        {
          title: "自定义查询",
          icon: "icon-draw-polygon",
          key: "drawPolygon",
          click: () => {
            this.$emit("startDraw");
            if (this.map) {
              this.map.closeDraw(true);
              this.map.drawPolygon();
            }
          },
        },
        {
          title: "清除",
          icon: "icon-draw-clear",
          key: "drawClear",
          click: () => {
            this.$emit("startDraw");
            this.map && this.map.closeDraw(true);
            this.searchPoiKey = "";
          },
        },
      ];
      return list.filter((item) => mapTools.includes(item.key));
    },
    // 图例
    isLegendsShow() {
      return !!this.legendsObj;
    },
    legends() {
      return this.legendsObj.list;
    },
  },
  watch: {
    searchPoiKey(newval) {
      let select = this.$refs.poiSelect;
      select.setQuery(newval);
    },
  },
  methods: {
    async initData() {
      let options = { id: this.mapId, level: this.level, center: this.center };
      this.map = new DTMap(options);
      await this.map.loaded();
      this.isLoaded = true;
      this.initMapEvents();
      if (!this.isCity && !this.ignoreFitChina) {
        let bounds = getChinaBounds();
        this.map.fitBounds(bounds);
      }
      let limitBounds = getLimitChinaBounds();
      this.map.setLimitBounds(limitBounds);
      this.initPlaceSearch();
      await this.initLegendLayers();
      this.$emit("loaded");
    },
    // map的监听
    initMapEvents() {
      this.map.on("click", (e) => {
        // console.log(e);
      });
      this.map.on("contextmenu", (e) => {
        this.$emit("contextmenu", e);
      });
      this.map.on("zoom", (zoom) => {
        this.zoom = zoom;
        this.$emit("zoom", zoom);
        // console.log("zoom:", zoom);
      });
      let inter_mousemove = null;
      this.map.on("mousemove", () => {
        if (this.isWatchLocation) {
          // 自动更改当前位置城市
          clearTimeout(inter_mousemove);
          inter_mousemove = setTimeout(() => {
            if (this.zoom > 9) {
              this.getLocationCenter();
            }
          }, 400);
        }
      });
      this.map.on("draw.point", (geojson) => {
        geojson.properties.drawPoint = true;
        this.$emit("drawPoint", geojson);
        this.map.setCursor("auto");
      });
      this.map.on("draw.circle", (geojson) => {
        geojson.properties.drawCircle = true;
        this.$emit("drawPolygon", geojson);
        this.map.setCursor("auto");
      });
      this.map.on("draw.rectangle", (geojson) => {
        geojson.properties.drawRectangle = true;
        this.$emit("drawPolygon", geojson);
        this.map.setCursor("auto");
      });
      this.map.on("draw.polygon", (geojson) => {
        geojson.properties.drawPolygon = true;
        this.$emit("drawPolygon", geojson);
        this.map.setCursor("auto");
      });
    },
    // 点位搜索
    initPlaceSearch() {
      if (!this.isShowSearchAddress) {
        return;
      }
      // let dom = this.$refs.toolbarSearch.$el.querySelector("input");
      // this.map.initAutoComplete(dom, (poi) => {
      //   this.$emit("searchPoi", poi);
      // });
    },
    async handleSearchPoi(keyword) {
      this.searchPoiLoading = true;
      // let keyword = this.searchPoiKey;
      let result = await this.map.searchPlace(keyword, {
        city: this.cityName,
      });
      let list = result.poiList.pois;
      this.poiList = list;
      this.searchPoiLoading = false;
    },
    async handleSelectSearchPoi(item) {
      let name = item.value;
      this.searchPoiKey = name;
      let selectItem;
      for (let i = 0, n = this.poiList.length; i < n; i++) {
        let item = this.poiList[i];
        if (item.name === name) {
          selectItem = item;
          break;
        }
      }
      if (!selectItem) {
        return;
      }
      let location = selectItem.location;
      let center = [location.lng, location.lat];
      const res = await GetDetailBrowsingRecord({
        module: "locationDetail",
        detailID: JSON.stringify({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: center,
          },
        }),
      });
      if (res && res.code == 200 && res.data == "success") {
        this.$emit("searchPoi", {
          center: center,
          properties: {
            address: selectItem.address,
            title: selectItem.name,
          },
        });
      }
    },
    handleEnterInput() {
      if (this.poiList.length > 0) {
        let value = this.poiList[0].name;
        this.handleSelectSearchPoi({
          value: value,
        });
        let select = this.$refs.poiSelect;
        select.hideMenu();
      }
    },
    handleSearchClear() {
      let select = this.$refs.poiSelect;
      select.clearSingleSelect();
      this.poiList = [];
    },
    // 添加点位
    setMarkers(layerId, features, options) {
      if (!this.map) {
        return;
      }
      let contentFun = null;
      if (options) {
        contentFun = options.content;
      }
      let layer = this.layerMap.get(layerId);
      if (!layer) {
        layer = new DTMap.MarkerLayer({
          id: layerId,
          icon: options.icon,
          iconSize: options.iconSize,
          anchor: options.anchor,
          contentFun: (feature) => {
            let content = contentFun && contentFun(feature);
            return content;
          },
        });
        layer.on("click", (obj) => {
          this.$emit("click", obj.feature);
        });
        layer.on("contextmenu", (obj) => {
          this.$emit("contextmenu", obj.feature);
        });
        this.layerMap.set(layerId, layer);
      }
      this.map.addLayer(layer);
      let sourceData = {
        type: "FeatureCollection",
        features,
      };
      layer.setFeatures(sourceData);
    },
    setMassMarkers(layerId, features, options) {
      if (!this.map) {
        return;
      }
      let layer = this.layerMap.get(layerId);
      if (!layer) {
        layer = new DTMap.MassMarkerLayer({
          id: layerId,
          icon: options.icon,
          iconSize: options.iconSize,
          anchor: options.anchor,
          zIndex: options.zIndex,
        });
        layer.on("click", (obj) => {
          this.$emit("click", obj.feature);
        });
        layer.on("contextmenu", (obj) => {
          this.$emit("contextmenu", obj.feature);
        });
        this.layerMap.set(layerId, layer);
      }
      this.map.addLayer(layer);
      let sourceData = {
        type: "FeatureCollection",
        features,
      };
      layer.setFeatures(sourceData);
    },
    // 添加热力图
    setHeatMap(layerId, features, options = {}) {
      if (!this.map) {
        return;
      }
      let max = options.max;
      let layer = this.layerMap.get(layerId);
      if (!layer) {
        layer = new DTMap.HeatmapLayer({
          id: layerId,
          max: max,
        });
        this.layerMap.set(layerId, layer);
      }
      this.map.addLayer(layer);
      layer.setData(features);
    },
    // 更改layer的显示状态
    changeLayerShow(layerId, _tag) {
      let layer = this.layerMap.get(layerId);
      if (!layer) {
        return;
      }
      let tag = _tag;
      if (tag === undefined) {
        tag = layer.display;
      }
      if (tag) {
        layer.show();
      } else {
        layer.hide();
      }
    },
    setPolygons(layerId, features, options) {
      if (!this.map) {
        return;
      }
      let layer = this.layerMap.get(layerId);
      if (!layer) {
        layer = new DTMap.PolygonLayer({
          id: layerId,
          strokeColor: options.strokeColor,
          strokeOpacity: options.strokeOpacity,
          strokeWeight: options.strokeWeight,
          fillColor: options.fillColor,
          fillOpacity: options.fillOpacity,
          strokeStyle: options.strokeStyle,
        });
        layer.on("click", (obj) => {
          this.$emit("click", obj.feature);
        });
        layer.on("contextmenu", (obj) => {
          this.$emit("contextmenu", obj.feature);
        });
        this.layerMap.set(layerId, layer);
      }
      this.map.addLayer(layer);
      let sourceData = {
        type: "FeatureCollection",
        features,
      };
      layer.setFeatures(sourceData);
    },
    setPolyLines(layerId, features, options) {
      if (!this.map) {
        return;
      }
      let layer = this.layerMap.get(layerId);
      if (!layer) {
        layer = new DTMap.LineLayer({
          id: layerId,
          strokeColor: options.strokeColor,
          strokeOpacity: options.strokeOpacity,
          strokeWeight: options.strokeWeight,
          strokeStyle: options.strokeStyle,
        });
        layer.on("click", (obj) => {
          this.$emit("click", obj.feature);
        });
        layer.on("contextmenu", (obj) => {
          this.$emit("contextmenu", obj.feature);
        });
        this.layerMap.set(layerId, layer);
      }
      this.map.addLayer(layer);
      let sourceData = {
        type: "FeatureCollection",
        features,
      };
      layer.setFeatures(sourceData);
    },
    setLegendPolygon(index, features, options) {
      let layerId = this.getLegendLayerId(index);
      this.setPolygons(layerId, features, options);
      this.legendsNums[index] = features.length;
    },
    // 获取当前中心点
    getLocationCenter() {
      if (!this.isLoaded) {
        return;
      }
      let center = this.map.getCenter();
      this.getLocationAddress(center);
    },
    // 获取地图中心点
    getMapCenter() {
      let center = this.map.getCenter();
      return center
    },
    // 设置地图中心点
    setMapCenter(center) {
      this.map.map.setCenter(center)
    },
    async getLocationAddress(lnglat) {
      if (!this.isLoaded) {
        return;
      }
      let res = await this.map.getAddress(lnglat);
      let cityName = res.addressComponent.city || res.addressComponent.province;
      if (cityName) {
        this.changeCityName(cityName);
      }
    },
    // --- 图例相关 ---
    async initLegendLayers() {
      if (!this.isLegendsShow) {
        return;
      }
      let funs = [];
      let imgUrl = "/images/icon_circle01.png";
      let layerIds = [];
      for (let i = 0, n = this.legends.length; i < n; i++) {
        let item = this.legends[i];
        funs.push(
          handlePngAsync(imgUrl, {
            color: item.color,
          })
        );
      }
      let icons = await Promise.all(funs);
      let numsObj = {};
      for (let i = 0, n = this.legends.length; i < n; i++) {
        let legend = this.legends[i];
        let layerId = this.getLegendLayerId(i);
        layerIds.push(layerId);
        if (legend.type === "polygons") {
          this.setPolygons(layerId, [], {});
        } else if (legend.type === "marker") {
          this.setMassMarkers(layerId, [], {
            icon: "/images/marker_blue2.png",
            iconSize: [22, 33],
            anchor: [11, 28],
            zIndex: 9999,
          });
        } else {
          this.setMassMarkers(layerId, [], {
            icon: icons[i],
            iconSize: [8, 8],
            anchor: [4, 4],
            zIndex: 9999,
          });
        }
        if (legend.show !== undefined) {
          this.changeLayerShow(layerId, legend.show);
        }

        numsObj[i] = 0;
      }
      this.legendsNums = numsObj;
      this.legendsLayerIds = layerIds;
    },
    getLegendLayerId(index) {
      return "legend_layer_" + index;
    },
    setLegendSource(index, features) {
      let layerId = this.getLegendLayerId(index);
      let layer = this.layerMap.get(layerId);
      let sourceData = {
        type: "FeatureCollection",
        features,
      };
      layer.setFeatures(sourceData);
      this.legendsNums[index] = features.length;
    },

    changeLegendShow(index, isShow) {
      let layerId = this.getLegendLayerId(index);
      this.changeLayerShow(layerId, isShow);
    },
    clearLegends() {
      for (let i = 0, n = this.legends.length; i < n; i++) {
        this.setLegendSource(i, []);
      }
    },
    // --- 图例相关end ---
    async setDeepSource(deep, adcode, obj) {},
    // 更换城市
    selectCityName(city) {
      this.cityName = city.cname;
      this.$emit("selectCity", {
        adcode: city.adcode,
        name: city.cname,
      });
    },
    changeCityName(cityName) {
      this.cityName = cityName;
    },
    // 搜索行政区划
    async searchDistrict(adcode, returnAll, subDeep) {
      await this.waitLoaded();
      let data = await this.map.searchDistrict({
        adcode,
        isAll: returnAll,
        subDeep,
      });
      return data;
    },
    // 跳转行政区划
    async fitBoundsDistrict(adcode) {
      let districtObj = await this.searchDistrict(adcode, true, 1);
      let bbox = districtObj.bbox;
      this.fitBounds(bbox);
    },
    // 打开地图弹窗
    openInfoWindow(lnglat, content, opts) {
      let infoWindow =
        this.map && this.map.openInfoWindow(lnglat, content, opts);
      return infoWindow;
    },
    closeWindowInfo() {
      this.map && this.map.closeWindowInfo();
    },
    waitLoaded() {
      return new Promise((resolve) => {
        let inter = setInterval(() => {
          if (this.isLoaded) {
            clearInterval(inter);
            resolve();
          }
        }, 10);
      });
    },
    fitBounds(bounds, immediately = false, avoid = null) {
      this.map && this.map.fitBounds(bounds, { immediately, avoid });
    },
    fitPointBounds(point, { radius = 3000, immediately, avoid }) {
      let center = point;
      let geom = turf.circle(center, radius / 1000, {
        steps: 64,
        units: "kilometers",
      });
      this.fitBounds(geom, immediately, avoid);
    },
    clearMap() {
      this.map.clear();
    },
    resize() {
      this.map && this.map.resize();
    },
    // 返回上一级
    handleBackClick() {
      this.$emit("handleBackClick");
    },
  },
  mounted() {
    this.mapId = this.id || "mapContainer";
    this.$nextTick(() => {
      this.initData();
    });
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
    if (this.map) {
      this.map.destroy();
      this.map = null;
    }
  },
  components: {
    CityBtn,
    MapLegends,
  },
};
</script>

<style lang="less"></style>
