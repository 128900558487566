import {MassMarker} from '../layer.js';
import {layerMixin} from './layer.js';
import {cloneObj} from '../map.utils.js';

class AmapMassMarker extends layerMixin(MassMarker) {
  AMap = null;
  map = null;
  massMarker = null;

  constructor(opts = {}) {
    super();
    let AMap = window.AMap;
    this.AMap = AMap;
    let id = opts.id || '';
    this.id = id;
    let style = [];
    if (opts.style && Array.isArray(opts.style)) {
      for (let i = 0, n = opts.style.length; i < n; i++) {
        let item = opts.style;
        style.push({
          url: item.icon,
          size: item.iconSize,
          anchor: item.anchor,
          zIndex: item.zIndex,
        })
      }
    } else {
      style.push(
        {
          url: opts.icon,
          size: opts.iconSize,
          anchor: opts.anchor,
          zIndex: opts.zIndex,
        }
      )
    }
    super.setStyle(style);
    this.setFeatures();
  }
  setFeatures(sourceData) {
    let AMap = this.AMap;
    let features = [];
    if (sourceData) {
      if (Array.isArray(sourceData)) {
        features = sourceData;
      } else if (sourceData.type === 'FeatureCollection') {
        features = sourceData.features || [];
      }
    }
    features = super.setFeatures(features);
    let list = [];
    for (let i = 0, n = features.length; i < n; i++) {
      let feature = features[i];
      let item = {
        lnglat: feature.geometry.coordinates,
        feature_id: feature.feature_id,
        style: 0,
      };
      list.push(item);
    }

    let bindLayerEvents = this.bindLayerEvents.bind(this);
    let style = cloneObj(this.layerStyle);
    if (!this.massMarker) {
      let styles = [];
      for (let i = 0, n = style.length; i < n; i++) {
        let item = style[i];
        let size = item.size;
        let anchor = item.anchor;
        let opt = {
          url: item.url,
        }
        if (size) {
          opt.size = new AMap.Size(size[0], size[1]);
        }
        if (anchor) {
          opt.anchor = new AMap.Pixel(anchor[0], anchor[1]);
        }
        if (item.zIndex !== undefined) {
          opt.zIndex = item.zIndex;
        }
        styles.push(opt);
      }
      this.massMarker = new AMap.MassMarks(list, {
        zIndex: style.zIndex || 99,
        style: styles,
        alwaysRender: false,
        cursor: 'pointer',
      });
      bindLayerEvents(this.massMarker);
    } else {
      this.massMarker.setData(list);
      this.massMarker.setzIndex(9999);
    }
  }
  addTo(map) {
    this.map = map;
    if (this.massMarker) {
      this.massMarker.setMap(map);
    }
  }
  setMap(map) {
    this.map = map;
    if (this.massMarker) {
      this.massMarker.setMap(map);
    }
  }
  show() {
    this.massMarker.show();
    super.show();
  }
  hide() {
    this.massMarker.hide();
    super.hide();
  }
}

export default AmapMassMarker;
