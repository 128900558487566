import {HeatmapLayer} from '../layer.js';
import {cloneObj} from '../map.utils.js';
class AmapHeatMapLayer extends HeatmapLayer {
  AMap = null;
  map = null;
  heatmapLayer = null;
  maxCount = 0;
  gradient = {};
  radius = 30;
  opacity = [0, 1];
  constructor(opts = {}) {
    super();
    let AMap = window.AMap;
    this.AMap = AMap;
    let id = opts.id || '';
    this.id = id;
    this.maxCount = opts.max || 0;
    let normalColor = {
      0.5: "blue",
      0.65: "rgb(117,211,248)",
      0.7: "rgb(0, 255, 0)",
      0.9: "#ffea00",
      1.0: "red"
    }
    this.gradient = opts.gradient || normalColor;
    this.radius = opts.radius || 25;
    this.opacity = opts.opacity || [0, 0.8];
    this.setData();
  }
  setData(_features) {
    let features = _features || [];
    let AMap = this.AMap;
    let list = [];
    for (let i = 0, n = features.length; i < n; i++) {
      let feature = features[i];
      let coordinates =feature.geometry.coordinates;
      let properties = feature.properties;
      let item = {
        lng: coordinates[0],
        lat: coordinates[1],
        count: properties.count || 1
      };
      list.push(item);
    }
    if (!this.heatmapLayer) {
      this.heatmapLayer = new AMap.HeatMap(this.map, {
        radius: this.radius,
        gradient: this.gradient,
        opacity: this.opacity,
      });
    }
    if (list.length > 0) {
      let options = {
        data: list,
      }
      if (this.maxCount) {
        options.max = this.maxCount;
      }
      this.heatmapLayer.setDataSet(options);
    }
  }
  addTo(map) {
    this.map = map;
    if (this.heatmapLayer) {
      this.heatmapLayer.setMap(map);
    }
  }
  setMap(map) {
    this.map = map;
  }
  show() {
    this.heatmapLayer.show();
    super.show();
  }
  hide() {
    this.heatmapLayer.hide();
    super.hide();
  }
}
export default AmapHeatMapLayer;
