import DTAMap from "./amap/index.js";
import config from './config.js';
let mapObj = {
  amap: DTAMap
}
export default class DTMap {
  constructor(options) {
    const mapType = config().mapType;
    let Map = mapObj[mapType];
    if (!Map) {
      return null;
    }
    let map = new Map(options);
    return map;
  }
  static get PolygonLayer() {
    const mapType = config().mapType;
    let Map = mapObj[mapType];
    return Map && Map.PolygonLayer;
  }
  static get MarkerLayer() {
    const mapType = config().mapType;
    let Map = mapObj[mapType];
    return Map && Map.MarkerLayer;
  }
  static get LineLayer() {
    const mapType = config().mapType;
    let Map = mapObj[mapType];
    return Map && Map.LineLayer;
  }
  static get Overlayer() {
    const mapType = config().mapType;
    let Map = mapObj[mapType];
    return Map && Map.Overlayer;
  }
  static get HeatmapLayer() {
    const mapType = config().mapType;
    let Map = mapObj[mapType];
    return Map && Map.HeatmapLayer;
  }
  static get MassMarkerLayer() {
    const mapType = config().mapType;
    let Map = mapObj[mapType];
    return Map && Map.MassMarkerLayer;
  }
}
window.DTMap = DTMap;
