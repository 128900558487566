import {LineLayer} from '../layer';
import { cloneObj } from '../map.utils';
import {layerMixin} from './layer.js';
class AmapLineLayer extends layerMixin(LineLayer) {
  lines = null;
  AMap = null;
  map = null;
  constructor(opts) {
    super()
    let AMap = window.AMap;
    this.AMap = AMap;

    let id = opts.id || '';
    this.id = id;
    this.title = opts.title || '';

    let style = {
      strokeColor: opts.strokeColor || '#3519FB',
      strokeOpacity: opts.strokeOpacity || 0.4,
      strokeWeight: opts.strokeWeight || 1.6,
      strokeStyle: opts.strokeStyle || 'solid',
    }
    super.setStyle(style);
    this.setFeatures();
  }
  setFeatures(sourceData) {
    let AMap = this.AMap;
    let features = sourceData && sourceData.features || [];
    features = super.setFeatures(features);
    let bindLayerEvents = this.bindLayerEvents.bind(this);
    
    features = features.map(feature => {
      return {
        type: feature.type,
        geometry: feature.geometry,
        properties: {
          feature_id: feature.feature_id
        }
      };
    });
    let newSourceData = {
      type: 'FeatureCollection',
      features,
    };
    if (!this.lines) {
      this.lines = new AMap.GeoJSON({
        geoJSON: newSourceData,
        getPolyline: (geojson, lnglats) => {
          let style = cloneObj(this.layerStyle);
          style.path = lnglats;
          style.extData = {
            feature_id: geojson.properties.feature_id
          };
          let polyline = new AMap.Polyline(style);
          bindLayerEvents(polyline);
          return polyline;
        }
      })
    } else {
      this.lines.importData(newSourceData);
    }
  }
  addTo(map) {
    this.map = map;
    this.lines.addTo(map);
  }
  setMap(map) {
    this.map = map;
    if (this.lines) {
      this.lines.setMap(map);
    }
  }
  show() {
    this.lines.show();
    super.show();
  }
  hide() {
    this.lines.hide();
    super.hide();
  }
}
export default AmapLineLayer;
