const Citys = {
  86: {
    110000: {
      cname: "北京市",
      pyname: "Bei Jing Shi",
      lon: "116.40717",
      lat: "39.90469",
    },
    120000: {
      cname: "天津市",
      pyname: "Tian Jin Shi",
      lon: "117.199371",
      lat: "39.0851",
    },
    130000: {
      cname: "河北省",
      pyname: "He Bei Sheng",
      lon: "114.469789",
      lat: "38.03599",
    },
    140000: {
      cname: "山西省",
      pyname: "Shan Xi Sheng",
      lon: "112.562719",
      lat: "37.873431",
    },
    150000: {
      cname: "内蒙古自治区",
      pyname: "Nei Meng Gu Zi Zhi Qu",
      lon: "111.765219",
      lat: "40.817331",
    },
    210000: {
      cname: "辽宁省",
      pyname: "Liao Ning Sheng",
      lon: "123.429249",
      lat: "41.835651",
    },
    220000: {
      cname: "吉林省",
      pyname: "Ji Lin Sheng",
      lon: "125.326799",
      lat: "43.896161",
    },
    230000: {
      cname: "黑龙江省",
      pyname: "Hei Long Jiang Sheng",
      lon: "126.662849",
      lat: "45.742079",
    },
    310000: {
      cname: "上海市",
      pyname: "Shang Hai Shi",
      lon: "121.47375",
      lat: "31.23026",
    },
    320000: {
      cname: "江苏省",
      pyname: "Jiang Su Sheng",
      lon: "118.762949",
      lat: "32.06071",
    },
    330000: {
      cname: "浙江省",
      pyname: "Zhe Jiang Sheng",
      lon: "120.1536",
      lat: "30.265549",
    },
    340000: {
      cname: "安徽省",
      pyname: "An Hui Sheng",
      lon: "117.285651",
      lat: "31.861569",
    },
    350000: {
      cname: "福建省",
      pyname: "Fu Jian Sheng",
      lon: "119.296591",
      lat: "26.09982",
    },
    360000: {
      cname: "江西省",
      pyname: "Jiang Xi Sheng",
      lon: "115.910039",
      lat: "28.674171",
    },
    370000: {
      cname: "山东省",
      pyname: "Shan Dong Sheng",
      lon: "117.020579",
      lat: "36.668251",
    },
    410000: {
      cname: "河南省",
      pyname: "He Nan Sheng",
      lon: "113.75322",
      lat: "34.76571",
    },
    420000: {
      cname: "湖北省",
      pyname: "Hu Bei Sheng",
      lon: "114.342339",
      lat: "30.545391",
    },
    430000: {
      cname: "湖南省",
      pyname: "Hu Nan Sheng",
      lon: "112.983381",
      lat: "28.11263",
    },
    440000: {
      cname: "广东省",
      pyname: "Guang Dong Sheng",
      lon: "113.26627",
      lat: "23.13171",
    },
    450000: {
      cname: "广西壮族自治区",
      pyname: "Guang Xi Zhuang Zu Zi Zhi Qu",
      lon: "108.327611",
      lat: "22.815341",
    },
    460000: {
      cname: "海南省",
      pyname: "Hai Nan Sheng",
      lon: "110.348631",
      lat: "20.01997",
    },
    500000: {
      cname: "重庆市",
      pyname: "Chong Qing Shi",
      lon: "106.550729",
      lat: "29.564709",
    },
    510000: {
      cname: "四川省",
      pyname: "Si Chuan Sheng",
      lon: "104.07572",
      lat: "30.650881",
    },
    520000: {
      cname: "贵州省",
      pyname: "Gui Zhou Sheng",
      lon: "106.70722",
      lat: "26.598201",
    },
    530000: {
      cname: "云南省",
      pyname: "Yun Nan Sheng",
      lon: "102.709731",
      lat: "25.045299",
    },
    540000: {
      cname: "西藏自治区",
      pyname: "Xi Zang Zi Zhi Qu",
      lon: "91.11748",
      lat: "29.64725",
    },
    610000: {
      cname: "陕西省",
      pyname: "Shan Xi Sheng",
      lon: "108.95424",
      lat: "34.264859",
    },
    620000: {
      cname: "甘肃省",
      pyname: "Gan Su Sheng",
      lon: "103.826339",
      lat: "36.059421",
    },
    630000: {
      cname: "青海省",
      pyname: "Qing Hai Sheng",
      lon: "101.780111",
      lat: "36.62087",
    },
    640000: {
      cname: "宁夏回族自治区",
      pyname: "Ning Xia Hui Zu Zi Zhi Qu",
      lon: "106.25849",
      lat: "38.47122",
    },
    650000: {
      cname: "新疆维吾尔自治区",
      pyname: "Xin Jiang Wei Wu Er Zi Zhi Qu",
      lon: "87.627101",
      lat: "43.793431",
    },
    710000: {
      cname: "台湾",
      pyname: "Taiwan",
      lon: "120.883939",
      lat: "23.266025",
    },
    810000: {
      cname: "香港",
      pyname: "Xiang Gang",
      lon: "114.162952",
      lat: "22.282068",
    },
    820000: {
      cname: "澳门",
      pyname: "AoMen",
      lon: "113.54909",
      lat: "22.198951",
    },
  },
  130000: {
    130100: {
      cname: "石家庄市",
      pyname: "Shi Jia Zhuang Shi",
      lon: "114.514299",
      lat: "38.04276",
    },
    130200: {
      cname: "唐山市",
      pyname: "Tang Shan Shi",
      lon: "118.180579",
      lat: "39.63048",
    },
    130300: {
      cname: "秦皇岛市",
      pyname: "Qin Huang Dao Shi",
      lon: "119.59964",
      lat: "39.935449",
    },
    130400: {
      cname: "邯郸市",
      pyname: "Han Dan Shi",
      lon: "114.53918",
      lat: "36.62556",
    },
    130500: {
      cname: "邢台市",
      pyname: "Xing Tai Shi",
      lon: "114.504429",
      lat: "37.070549",
    },
    130600: {
      cname: "保定市",
      pyname: "Bao Ding Shi",
      lon: "115.46459",
      lat: "38.873961",
    },
    130700: {
      cname: "张家口市",
      pyname: "Zhang Jia Kou Shi",
      lon: "114.88755",
      lat: "40.82444",
    },
    130800: {
      cname: "承德市",
      pyname: "Cheng De Shi",
      lon: "117.963401",
      lat: "40.9515",
    },
    130900: {
      cname: "沧州市",
      pyname: "Cang Zhou Shi",
      lon: "116.838689",
      lat: "38.30441",
    },
    131000: {
      cname: "廊坊市",
      pyname: "Lang Fang Shi",
      lon: "116.683761",
      lat: "39.53775",
    },
    131100: {
      cname: "衡水市",
      pyname: "Heng Shui Shi",
      lon: "115.67054",
      lat: "37.738861",
    },
  },
  140000: {
    140100: {
      cname: "太原市",
      pyname: "Tai Yuan Shi",
      lon: "112.550671",
      lat: "37.87059",
    },
    140200: {
      cname: "大同市",
      pyname: "Da Tong Shi",
      lon: "113.300011",
      lat: "40.076369",
    },
    140300: {
      cname: "阳泉市",
      pyname: "Yang Quan Shi",
      lon: "113.580471",
      lat: "37.85668",
    },
    140400: {
      cname: "长治市",
      pyname: "Chang Zhi Shi",
      lon: "113.116491",
      lat: "36.195809",
    },
    140500: {
      cname: "晋城市",
      pyname: "Jin Cheng Shi",
      lon: "112.851131",
      lat: "35.490391",
    },
    140600: {
      cname: "朔州市",
      pyname: "Shuo Zhou Shi",
      lon: "112.43181",
      lat: "39.331591",
    },
    140700: {
      cname: "晋中市",
      pyname: "Jin Zhong Shi",
      lon: "112.75278",
      lat: "37.68702",
    },
    140800: {
      cname: "运城市",
      pyname: "Yun Cheng Shi",
      lon: "111.00699",
      lat: "35.02628",
    },
    140900: {
      cname: "忻州市",
      pyname: "Xin Zhou Shi",
      lon: "112.73418",
      lat: "38.416699",
    },
    141000: {
      cname: "临汾市",
      pyname: "Lin Fen Shi",
      lon: "111.51962",
      lat: "36.08822",
    },
    141100: {
      cname: "吕梁市",
      pyname: "Lv Liang Shi",
      lon: "111.141649",
      lat: "37.51934",
    },
  },
  150000: {
    150100: {
      cname: "呼和浩特市",
      pyname: "Hu He Hao Te Shi",
      lon: "111.75199",
      lat: "40.841491",
    },
    150200: {
      cname: "包头市",
      pyname: "Bao Tou Shi",
      lon: "109.84026",
      lat: "40.65737",
    },
    150300: {
      cname: "乌海市",
      pyname: "Wu Hai Shi",
      lon: "106.79546",
      lat: "39.653839",
    },
    150400: {
      cname: "赤峰市",
      pyname: "Chi Feng Shi",
      lon: "118.888941",
      lat: "42.2586",
    },
    150500: {
      cname: "通辽市",
      pyname: "Tong Liao Shi",
      lon: "122.24469",
      lat: "43.65247",
    },
    150600: {
      cname: "鄂尔多斯市",
      pyname: "E Er Duo Si Shi",
      lon: "109.78174",
      lat: "39.607849",
    },
    150700: {
      cname: "呼伦贝尔市",
      pyname: "Hu Lun Bei Er Shi",
      lon: "119.76584",
      lat: "49.21163",
    },
    150800: {
      cname: "巴彦淖尔市",
      pyname: "Ba Yan Nao Er Shi",
      lon: "107.38773",
      lat: "40.743171",
    },
    150900: {
      cname: "乌兰察布市",
      pyname: "Wu Lan Cha Bu Shi",
      lon: "113.133761",
      lat: "40.993911",
    },
    152200: {
      cname: "兴安盟",
      pyname: "Xing An Meng",
      lon: "122.038179",
      lat: "46.082079",
    },
    152500: {
      cname: "锡林郭勒盟",
      pyname: "Xi Lin Guo Le Meng",
      lon: "116.04775",
      lat: "43.933201",
    },
    152900: {
      cname: "阿拉善盟",
      pyname: "A La Shan Meng",
      lon: "105.72898",
      lat: "38.85153",
    },
  },
  210000: {
    210100: {
      cname: "沈阳市",
      pyname: "Shen Yang Shi",
      lon: "123.432359",
      lat: "41.805629",
    },
    210200: {
      cname: "大连市",
      pyname: "Da Lian Shi",
      lon: "121.614759",
      lat: "38.913689",
    },
    210300: {
      cname: "鞍山市",
      pyname: "An Shan Shi",
      lon: "122.994601",
      lat: "41.107769",
    },
    210400: {
      cname: "抚顺市",
      pyname: "Fu Shun Shi",
      lon: "123.95722",
      lat: "41.879709",
    },
    210500: {
      cname: "本溪市",
      pyname: "Ben Xi Shi",
      lon: "123.76686",
      lat: "41.29413",
    },
    210600: {
      cname: "丹东市",
      pyname: "Dan Dong Shi",
      lon: "124.356009",
      lat: "39.9998",
    },
    210700: {
      cname: "锦州市",
      pyname: "Jin Zhou Shi",
      lon: "121.127029",
      lat: "41.09515",
    },
    210800: {
      cname: "营口市",
      pyname: "Ying Kou Shi",
      lon: "122.2349",
      lat: "40.666829",
    },
    210900: {
      cname: "阜新市",
      pyname: "Fu Xin Shi",
      lon: "121.670111",
      lat: "42.02166",
    },
    211000: {
      cname: "辽阳市",
      pyname: "Liao Yang Shi",
      lon: "123.237359",
      lat: "41.26809",
    },
    211100: {
      cname: "盘锦市",
      pyname: "Pan Jin Shi",
      lon: "122.070779",
      lat: "41.119961",
    },
    211200: {
      cname: "铁岭市",
      pyname: "Tie Ling Shi",
      lon: "123.842411",
      lat: "42.2862",
    },
    211300: {
      cname: "朝阳市",
      pyname: "Chao Yang Shi",
      lon: "120.450801",
      lat: "41.57347",
    },
    211400: {
      cname: "葫芦岛市",
      pyname: "Hu Lu Dao Shi",
      lon: "120.83699",
      lat: "40.711",
    },
  },
  220000: {
    220100: {
      cname: "长春市",
      pyname: "Chang Chun Shi",
      lon: "125.32357",
      lat: "43.81602",
    },
    220200: {
      cname: "吉林市",
      pyname: "Ji Lin Shi",
      lon: "126.54944",
      lat: "43.837841",
    },
    220300: {
      cname: "四平市",
      pyname: "Si Ping Shi",
      lon: "124.35036",
      lat: "43.166461",
    },
    220400: {
      cname: "辽源市",
      pyname: "Liao Yuan Shi",
      lon: "125.143681",
      lat: "42.888049",
    },
    220500: {
      cname: "通化市",
      pyname: "Tong Hua Shi",
      lon: "125.9399",
      lat: "41.72829",
    },
    220600: {
      cname: "白山市",
      pyname: "Bai Shan Shi",
      lon: "126.424429",
      lat: "41.940801",
    },
    220700: {
      cname: "松原市",
      pyname: "Song Yuan Shi",
      lon: "124.82515",
      lat: "45.1411",
    },
    220800: {
      cname: "白城市",
      pyname: "Bai Cheng Shi",
      lon: "122.838711",
      lat: "45.619601",
    },
    222400: {
      cname: "延边朝鲜族自治州",
      pyname: "Yan Bian Chao Xian Zu Zi Zhi Zhou",
      lon: "129.509099",
      lat: "42.891189",
    },
  },
  230000: {
    230100: {
      cname: "哈尔滨市",
      pyname: "Ha Er Bin Shi",
      lon: "126.535801",
      lat: "45.802159",
    },
    230200: {
      cname: "齐齐哈尔市",
      pyname: "Qi Qi Ha Er Shi",
      lon: "123.91796",
      lat: "47.35431",
    },
    230300: {
      cname: "鸡西市",
      pyname: "Ji Xi Shi",
      lon: "130.96954",
      lat: "45.295241",
    },
    230400: {
      cname: "鹤岗市",
      pyname: "He Gang Shi",
      lon: "130.297849",
      lat: "47.349889",
    },
    230500: {
      cname: "双鸭山市",
      pyname: "Shuang Ya Shan Shi",
      lon: "131.159099",
      lat: "46.64658",
    },
    230600: {
      cname: "大庆市",
      pyname: "Da Qing Shi",
      lon: "125.103071",
      lat: "46.58758",
    },
    230700: {
      cname: "伊春市",
      pyname: "Yi Chun Shi",
      lon: "128.84049",
      lat: "47.72752",
    },
    230800: {
      cname: "佳木斯市",
      pyname: "Jia Mu Si Shi",
      lon: "130.320599",
      lat: "46.800191",
    },
    230900: {
      cname: "七台河市",
      pyname: "Qi Tai He Shi",
      lon: "131.00306",
      lat: "45.770651",
    },
    231000: {
      cname: "牡丹江市",
      pyname: "Mu Dan Jiang Shi",
      lon: "129.632439",
      lat: "44.552691",
    },
    231100: {
      cname: "黑河市",
      pyname: "Hei He Shi",
      lon: "127.52852",
      lat: "50.24523",
    },
    231200: {
      cname: "绥化市",
      pyname: "Sui Hua Shi",
      lon: "126.969321",
      lat: "46.652461",
    },
    232700: {
      cname: "大兴安岭地区",
      pyname: "Da Xing An Ling Di Qu",
      lon: "124.592159",
      lat: "51.92398",
    },
  },
  320000: {
    320100: {
      cname: "南京市",
      pyname: "Nan Jing Shi",
      lon: "118.796469",
      lat: "32.058381",
    },
    320200: {
      cname: "无锡市",
      pyname: "Wu Xi Shi",
      lon: "120.31237",
      lat: "31.49099",
    },
    320300: {
      cname: "徐州市",
      pyname: "Xu Zhou Shi",
      lon: "117.28577",
      lat: "34.204401",
    },
    320400: {
      cname: "常州市",
      pyname: "Chang Zhou Shi",
      lon: "119.97365",
      lat: "31.81072",
    },
    320500: {
      cname: "苏州市",
      pyname: "Su Zhou Shi",
      lon: "120.58319",
      lat: "31.29834",
    },
    320600: {
      cname: "南通市",
      pyname: "Nan Tong Shi",
      lon: "120.893711",
      lat: "31.979579",
    },
    320700: {
      cname: "连云港市",
      pyname: "Lian Yun Gang Shi",
      lon: "119.222949",
      lat: "34.596691",
    },
    320800: {
      cname: "淮安市",
      pyname: "Huai An Shi",
      lon: "119.015951",
      lat: "33.610161",
    },
    320900: {
      cname: "盐城市",
      pyname: "Yan Cheng Shi",
      lon: "120.161641",
      lat: "33.34951",
    },
    321000: {
      cname: "扬州市",
      pyname: "Yang Zhou Shi",
      lon: "119.412691",
      lat: "32.393581",
    },
    321100: {
      cname: "镇江市",
      pyname: "Zhen Jiang Shi",
      lon: "119.425",
      lat: "32.18959",
    },
    321200: {
      cname: "泰州市",
      pyname: "Tai Zhou Shi",
      lon: "119.92554",
      lat: "32.45546",
    },
    321300: {
      cname: "宿迁市",
      pyname: "Su Qian Shi",
      lon: "118.27549",
      lat: "33.961929",
    },
  },
  330000: {
    330100: {
      cname: "杭州市",
      pyname: "Hang Zhou Shi",
      lon: "120.15515",
      lat: "30.274149",
    },
    330200: {
      cname: "宁波市",
      pyname: "Ning Bo Shi",
      lon: "121.550269",
      lat: "29.873861",
    },
    330300: {
      cname: "温州市",
      pyname: "Wen Zhou Shi",
      lon: "120.69939",
      lat: "27.99492",
    },
    330400: {
      cname: "嘉兴市",
      pyname: "Jia Xing Shi",
      lon: "120.755499",
      lat: "30.745011",
    },
    330500: {
      cname: "湖州市",
      pyname: "Hu Zhou Shi",
      lon: "120.088049",
      lat: "30.893049",
    },
    330600: {
      cname: "绍兴市",
      pyname: "Shao Xing Shi",
      lon: "120.5802",
      lat: "30.03033",
    },
    330700: {
      cname: "金华市",
      pyname: "Jin Hua Shi",
      lon: "119.647589",
      lat: "29.078121",
    },
    330800: {
      cname: "衢州市",
      pyname: "Qu Zhou Shi",
      lon: "118.874191",
      lat: "28.93592",
    },
    330900: {
      cname: "舟山市",
      pyname: "Zhou Shan Shi",
      lon: "122.20778",
      lat: "29.985391",
    },
    331000: {
      cname: "台州市",
      pyname: "Tai Zhou Shi",
      lon: "121.42056",
      lat: "28.656109",
    },
    331100: {
      cname: "丽水市",
      pyname: "Li Shui Shi",
      lon: "119.92293",
      lat: "28.467201",
    },
  },
  340000: {
    340100: {
      cname: "合肥市",
      pyname: "He Fei Shi",
      lon: "117.22901",
      lat: "31.820571",
    },
    340200: {
      cname: "芜湖市",
      pyname: "Wu Hu Shi",
      lon: "118.43321",
      lat: "31.351569",
    },
    340300: {
      cname: "蚌埠市",
      pyname: "Beng Bu Shi",
      lon: "117.389321",
      lat: "32.91548",
    },
    340400: {
      cname: "淮南市",
      pyname: "Huai Nan Shi",
      lon: "116.9998",
      lat: "32.62549",
    },
    340500: {
      cname: "马鞍山市",
      pyname: "Ma An Shan Shi",
      lon: "118.506109",
      lat: "31.670671",
    },
    340600: {
      cname: "淮北市",
      pyname: "Huai Bei Shi",
      lon: "116.79834",
      lat: "33.954789",
    },
    340700: {
      cname: "铜陵市",
      pyname: "Tong Ling Shi",
      lon: "117.81232",
      lat: "30.944859",
    },
    340800: {
      cname: "安庆市",
      pyname: "An Qing Shi",
      lon: "117.063539",
      lat: "30.542941",
    },
    341000: {
      cname: "黄山市",
      pyname: "Huang Shan Shi",
      lon: "118.338661",
      lat: "29.715169",
    },
    341100: {
      cname: "滁州市",
      pyname: "Chu Zhou Shi",
      lon: "118.316829",
      lat: "32.30181",
    },
    341200: {
      cname: "阜阳市",
      pyname: "Fu Yang Shi",
      lon: "115.81495",
      lat: "32.889631",
    },
    341300: {
      cname: "宿州市",
      pyname: "Su Zhou Shi",
      lon: "116.963911",
      lat: "33.646139",
    },
    341500: {
      cname: "六安市",
      pyname: "Lu An Shi",
      lon: "116.52324",
      lat: "31.734881",
    },
    341600: {
      cname: "亳州市",
      pyname: "Bo Zhou Shi",
      lon: "115.77931",
      lat: "33.844609",
    },
    341700: {
      cname: "池州市",
      pyname: "Chi Zhou Shi",
      lon: "117.491419",
      lat: "30.66469",
    },
    341800: {
      cname: "宣城市",
      pyname: "Xuan Cheng Shi",
      lon: "118.758661",
      lat: "30.940779",
    },
  },
  350000: {
    350100: {
      cname: "福州市",
      pyname: "Fu Zhou Shi",
      lon: "119.296469",
      lat: "26.07421",
    },
    350200: {
      cname: "厦门市",
      pyname: "Xia Men Shi",
      lon: "118.089479",
      lat: "24.47951",
    },
    350300: {
      cname: "莆田市",
      pyname: "Pu Tian Shi",
      lon: "119.007711",
      lat: "25.454",
    },
    350400: {
      cname: "三明市",
      pyname: "San Ming Shi",
      lon: "117.639221",
      lat: "26.26385",
    },
    350500: {
      cname: "泉州市",
      pyname: "Quan Zhou Shi",
      lon: "118.67587",
      lat: "24.873891",
    },
    350600: {
      cname: "漳州市",
      pyname: "Zhang Zhou Shi",
      lon: "117.64725",
      lat: "24.51347",
    },
    350700: {
      cname: "南平市",
      pyname: "Nan Ping Shi",
      lon: "118.17783",
      lat: "26.641519",
    },
    350800: {
      cname: "龙岩市",
      pyname: "Long Yan Shi",
      lon: "117.01722",
      lat: "25.075039",
    },
    350900: {
      cname: "宁德市",
      pyname: "Ning De Shi",
      lon: "119.54819",
      lat: "26.66571",
    },
  },
  360000: {
    360100: {
      cname: "南昌市",
      pyname: "Nan Chang Shi",
      lon: "115.857941",
      lat: "28.68202",
    },
    360200: {
      cname: "景德镇市",
      pyname: "Jing De Zhen Shi",
      lon: "117.17839",
      lat: "29.268689",
    },
    360300: {
      cname: "萍乡市",
      pyname: "Ping Xiang Shi",
      lon: "113.854271",
      lat: "27.622891",
    },
    360400: {
      cname: "九江市",
      pyname: "Jiu Jiang Shi",
      lon: "116.001461",
      lat: "29.70548",
    },
    360500: {
      cname: "新余市",
      pyname: "Xin Yu Shi",
      lon: "114.917131",
      lat: "27.81776",
    },
    360600: {
      cname: "鹰潭市",
      pyname: "Ying Tan Shi",
      lon: "117.069191",
      lat: "28.260191",
    },
    360700: {
      cname: "赣州市",
      pyname: "Gan Zhou Shi",
      lon: "114.934759",
      lat: "25.831089",
    },
    360800: {
      cname: "吉安市",
      pyname: "Ji An Shi",
      lon: "114.993761",
      lat: "27.113819",
    },
    360900: {
      cname: "宜春市",
      pyname: "Yi Chun Shi",
      lon: "114.41612",
      lat: "27.814429",
    },
    361000: {
      cname: "抚州市",
      pyname: "Fu Zhou Shi",
      lon: "116.35809",
      lat: "27.94781",
    },
    361100: {
      cname: "上饶市",
      pyname: "Shang Rao Shi",
      lon: "117.94357",
      lat: "28.454631",
    },
  },
  370000: {
    370100: {
      cname: "济南市",
      pyname: "Ji Nan Shi",
      lon: "116.994931",
      lat: "36.665291",
    },
    370200: {
      cname: "青岛市",
      pyname: "Qing Dao Shi",
      lon: "120.38299",
      lat: "36.06623",
    },
    370300: {
      cname: "淄博市",
      pyname: "Zi Bo Shi",
      lon: "118.0548",
      lat: "36.813099",
    },
    370400: {
      cname: "枣庄市",
      pyname: "Zao Zhuang Shi",
      lon: "117.32196",
      lat: "34.81071",
    },
    370500: {
      cname: "东营市",
      pyname: "Dong Ying Shi",
      lon: "118.674659",
      lat: "37.43365",
    },
    370600: {
      cname: "烟台市",
      pyname: "Yan Tai Shi",
      lon: "121.44801",
      lat: "37.463531",
    },
    370700: {
      cname: "潍坊市",
      pyname: "Wei Fang Shi",
      lon: "119.16176",
      lat: "36.70686",
    },
    370800: {
      cname: "济宁市",
      pyname: "Ji Ning Shi",
      lon: "116.58724",
      lat: "35.41459",
    },
    370900: {
      cname: "泰安市",
      pyname: "Tai An Shi",
      lon: "117.088401",
      lat: "36.199939",
    },
    371000: {
      cname: "威海市",
      pyname: "Wei Hai Shi",
      lon: "122.12171",
      lat: "37.513481",
    },
    371100: {
      cname: "日照市",
      pyname: "Ri Zhao Shi",
      lon: "119.52719",
      lat: "35.416461",
    },
    // 371200: {
    //   cname: "莱芜市",
    //   pyname: "Lai Wu Shi",
    //   lon: "117.676671",
    //   lat: "36.213589",
    // },
    371300: {
      cname: "临沂市",
      pyname: "Lin Yi Shi",
      lon: "118.356461",
      lat: "35.104651",
    },
    371400: {
      cname: "德州市",
      pyname: "De Zhou Shi",
      lon: "116.359271",
      lat: "37.435499",
    },
    371500: {
      cname: "聊城市",
      pyname: "Liao Cheng Shi",
      lon: "115.98549",
      lat: "36.45702",
    },
    371600: {
      cname: "滨州市",
      pyname: "Bin Zhou Shi",
      lon: "117.972791",
      lat: "37.382109",
    },
    371700: {
      cname: "菏泽市",
      pyname: "He Ze Shi",
      lon: "115.48115",
      lat: "35.233631",
    },
  },
  410000: {
    410100: {
      cname: "郑州市",
      pyname: "Zheng Zhou Shi",
      lon: "113.624931",
      lat: "34.74725",
    },
    410200: {
      cname: "开封市",
      pyname: "Kai Feng Shi",
      lon: "114.307309",
      lat: "34.797259",
    },
    410300: {
      cname: "洛阳市",
      pyname: "Luo Yang Shi",
      lon: "112.453609",
      lat: "34.618121",
    },
    410400: {
      cname: "平顶山市",
      pyname: "Ping Ding Shan Shi",
      lon: "113.192411",
      lat: "33.766089",
    },
    410500: {
      cname: "安阳市",
      pyname: "An Yang Shi",
      lon: "114.393099",
      lat: "36.097711",
    },
    410600: {
      cname: "鹤壁市",
      pyname: "He Bi Shi",
      lon: "114.29745",
      lat: "35.747001",
    },
    410700: {
      cname: "新乡市",
      pyname: "Xin Xiang Shi",
      lon: "113.926749",
      lat: "35.303229",
    },
    410800: {
      cname: "焦作市",
      pyname: "Jiao Zuo Shi",
      lon: "113.24201",
      lat: "35.215629",
    },
    410900: {
      cname: "濮阳市",
      pyname: "Pu Yang Shi",
      lon: "115.029321",
      lat: "35.76189",
    },
    411000: {
      cname: "许昌市",
      pyname: "Xu Chang Shi",
      lon: "113.852331",
      lat: "34.035701",
    },
    411100: {
      cname: "漯河市",
      pyname: "Luo He Shi",
      lon: "114.01681",
      lat: "33.581491",
    },
    411200: {
      cname: "三门峡市",
      pyname: "San Men Xia Shi",
      lon: "111.200299",
      lat: "34.772611",
    },
    411300: {
      cname: "南阳市",
      pyname: "Nan Yang Shi",
      lon: "112.528509",
      lat: "32.990729",
    },
    411400: {
      cname: "商丘市",
      pyname: "Shang Qiu Shi",
      lon: "115.65635",
      lat: "34.414271",
    },
    411500: {
      cname: "信阳市",
      pyname: "Xin Yang Shi",
      lon: "114.092791",
      lat: "32.14714",
    },
    411600: {
      cname: "周口市",
      pyname: "Zhou Kou Shi",
      lon: "114.696951",
      lat: "33.625829",
    },
    411700: {
      cname: "驻马店市",
      pyname: "Zhu Ma Dian Shi",
      lon: "114.02299",
      lat: "33.011419",
    },
    419001: {
      cname: "济源市",
      pyname: "Ji Yuan Shi",
      lon: "112.60273",
      lat: "35.06707",
    },
  },
  420000: {
    420100: {
      cname: "武汉市",
      pyname: "Wu Han Shi",
      lon: "114.30525",
      lat: "30.59276",
    },
    420200: {
      cname: "黄石市",
      pyname: "Huang Shi Shi",
      lon: "115.0389",
      lat: "30.199529",
    },
    420300: {
      cname: "十堰市",
      pyname: "Shi Yan Shi",
      lon: "110.79801",
      lat: "32.62918",
    },
    420500: {
      cname: "宜昌市",
      pyname: "Yi Chang Shi",
      lon: "111.286419",
      lat: "30.69186",
    },
    420600: {
      cname: "襄阳市",
      pyname: "Xiang Yang Shi",
      lon: "112.12255",
      lat: "32.009",
    },
    420700: {
      cname: "鄂州市",
      pyname: "E Zhou Shi",
      lon: "114.89495",
      lat: "30.390851",
    },
    420800: {
      cname: "荆门市",
      pyname: "Jing Men Shi",
      lon: "112.199451",
      lat: "31.03546",
    },
    420900: {
      cname: "孝感市",
      pyname: "Xiao Gan Shi",
      lon: "113.91645",
      lat: "30.924831",
    },
    421000: {
      cname: "荆州市",
      pyname: "Jing Zhou Shi",
      lon: "112.24069",
      lat: "30.334789",
    },
    421100: {
      cname: "黄冈市",
      pyname: "Huang Gang Shi",
      lon: "114.872381",
      lat: "30.45347",
    },
    421200: {
      cname: "咸宁市",
      pyname: "Xian Ning Shi",
      lon: "114.32245",
      lat: "29.841261",
    },
    421300: {
      cname: "随州市",
      pyname: "Sui Zhou Shi",
      lon: "113.382619",
      lat: "31.69013",
    },
    422800: {
      cname: "恩施土家族苗族自治州",
      pyname: "En Shi Tu Jia Zu Miao Zu Zi Zhi Zhou",
      lon: "109.488171",
      lat: "30.27217",
    },
    429004: {
      cname: "仙桃市",
      pyname: "Xian Tao Shi",
      lon: "113.454501",
      lat: "30.362511",
    },
    429005: {
      cname: "潜江市",
      pyname: "Qian Jiang Shi",
      lon: "112.899299",
      lat: "30.401469",
    },
    429006: {
      cname: "天门市",
      pyname: "Tian Men Shi",
      lon: "113.166139",
      lat: "30.66339",
    },
    429021: {
      cname: "神农架林区",
      pyname: "Shen Nong Jia Lin Qu",
      lon: "110.67556",
      lat: "31.74457",
    },
  },
  430000: {
    430100: {
      cname: "长沙市",
      pyname: "Chang Sha Shi",
      lon: "112.938861",
      lat: "28.22778",
    },
    430200: {
      cname: "株洲市",
      pyname: "Zhu Zhou Shi",
      lon: "113.133961",
      lat: "27.827669",
    },
    430300: {
      cname: "湘潭市",
      pyname: "Xiang Tan Shi",
      lon: "112.94411",
      lat: "27.82975",
    },
    430400: {
      cname: "衡阳市",
      pyname: "Heng Yang Shi",
      lon: "112.571951",
      lat: "26.89324",
    },
    430500: {
      cname: "邵阳市",
      pyname: "Shao Yang Shi",
      lon: "111.4677",
      lat: "27.2389",
    },
    430600: {
      cname: "岳阳市",
      pyname: "Yue Yang Shi",
      lon: "113.129191",
      lat: "29.357281",
    },
    430700: {
      cname: "常德市",
      pyname: "Chang De Shi",
      lon: "111.698539",
      lat: "29.03158",
    },
    430800: {
      cname: "张家界市",
      pyname: "Zhang Jia Jie Shi",
      lon: "110.47839",
      lat: "29.116671",
    },
    430900: {
      cname: "益阳市",
      pyname: "Yi Yang Shi",
      lon: "112.355161",
      lat: "28.553911",
    },
    431000: {
      cname: "郴州市",
      pyname: "Chen Zhou Shi",
      lon: "113.01485",
      lat: "25.770629",
    },
    431100: {
      cname: "永州市",
      pyname: "Yong Zhou Shi",
      lon: "111.61225",
      lat: "26.420341",
    },
    431200: {
      cname: "怀化市",
      pyname: "Huai Hua Shi",
      lon: "110.001599",
      lat: "27.56974",
    },
    431300: {
      cname: "娄底市",
      pyname: "Lou Di Shi",
      lon: "111.994579",
      lat: "27.697281",
    },
    433100: {
      cname: "湘西土家族苗族自治州",
      pyname: "Xiang Xi Tu Jia Zu Miao Zu Zi Zhi Zhou",
      lon: "109.73893",
      lat: "28.31173",
    },
  },
  440000: {
    440100: {
      cname: "广州市",
      pyname: "Guang Zhou Shi",
      lon: "113.26388",
      lat: "23.12946",
    },
    440200: {
      cname: "韶关市",
      pyname: "Shao Guan Shi",
      lon: "113.597231",
      lat: "24.810391",
    },
    440300: {
      cname: "深圳市",
      pyname: "Shen Zhen Shi",
      lon: "114.059559",
      lat: "22.54286",
    },
    440400: {
      cname: "珠海市",
      pyname: "Zhu Hai Shi",
      lon: "113.57668",
      lat: "22.270729",
    },
    440500: {
      cname: "汕头市",
      pyname: "Shan Tou Shi",
      lon: "116.682209",
      lat: "23.3535",
    },
    440600: {
      cname: "佛山市",
      pyname: "Fo Shan Shi",
      lon: "113.121921",
      lat: "23.021849",
    },
    440700: {
      cname: "江门市",
      pyname: "Jiang Men Shi",
      lon: "113.08161",
      lat: "22.57865",
    },
    440800: {
      cname: "湛江市",
      pyname: "Zhan Jiang Shi",
      lon: "110.358941",
      lat: "21.271339",
    },
    440900: {
      cname: "茂名市",
      pyname: "Mao Ming Shi",
      lon: "110.92523",
      lat: "21.66329",
    },
    441200: {
      cname: "肇庆市",
      pyname: "Zhao Qing Shi",
      lon: "112.46528",
      lat: "23.046901",
    },
    441300: {
      cname: "惠州市",
      pyname: "Hui Zhou Shi",
      lon: "114.41679",
      lat: "23.110751",
    },
    441400: {
      cname: "梅州市",
      pyname: "Mei Zhou Shi",
      lon: "116.122641",
      lat: "24.28844",
    },
    441500: {
      cname: "汕尾市",
      pyname: "Shan Wei Shi",
      lon: "115.375141",
      lat: "22.78566",
    },
    441600: {
      cname: "河源市",
      pyname: "He Yuan Shi",
      lon: "114.700651",
      lat: "23.74365",
    },
    441700: {
      cname: "阳江市",
      pyname: "Yang Jiang Shi",
      lon: "111.98288",
      lat: "21.858739",
    },
    441800: {
      cname: "清远市",
      pyname: "Qing Yuan Shi",
      lon: "113.05615",
      lat: "23.68201",
    },
    441900: {
      cname: "东莞市",
      pyname: "Dong Guan Shi",
      lon: "113.75161",
      lat: "23.02",
    },
    442000: {
      cname: "中山市",
      pyname: "Zhong Shan Shi",
      lon: "113.3926",
      lat: "22.515951",
    },
    445100: {
      cname: "潮州市",
      pyname: "Chao Zhou Shi",
      lon: "116.62296",
      lat: "23.656699",
    },
    445200: {
      cname: "揭阳市",
      pyname: "Jie Yang Shi",
      lon: "116.372711",
      lat: "23.54972",
    },
    445300: {
      cname: "云浮市",
      pyname: "Yun Fu Shi",
      lon: "112.044559",
      lat: "22.915169",
    },
  },
  450000: {
    450100: {
      cname: "南宁市",
      pyname: "Nan Ning Shi",
      lon: "108.366901",
      lat: "22.81673",
    },
    450200: {
      cname: "柳州市",
      pyname: "Liu Zhou Shi",
      lon: "109.415521",
      lat: "24.32543",
    },
    450300: {
      cname: "桂林市",
      pyname: "Gui Lin Shi",
      lon: "110.29002",
      lat: "25.273609",
    },
    450400: {
      cname: "梧州市",
      pyname: "Wu Zhou Shi",
      lon: "111.27913",
      lat: "23.477029",
    },
    450500: {
      cname: "北海市",
      pyname: "Bei Hai Shi",
      lon: "109.12008",
      lat: "21.48112",
    },
    450600: {
      cname: "防城港市",
      pyname: "Fang Cheng Gang Shi",
      lon: "108.354019",
      lat: "21.68712",
    },
    450700: {
      cname: "钦州市",
      pyname: "Qin Zhou Shi",
      lon: "108.65431",
      lat: "21.979701",
    },
    450800: {
      cname: "贵港市",
      pyname: "Gui Gang Shi",
      lon: "109.597641",
      lat: "23.11306",
    },
    450900: {
      cname: "玉林市",
      pyname: "Yu Lin Shi",
      lon: "110.180981",
      lat: "22.65451",
    },
    451000: {
      cname: "百色市",
      pyname: "Bai Se Shi",
      lon: "106.618381",
      lat: "23.902159",
    },
    451100: {
      cname: "贺州市",
      pyname: "He Zhou Shi",
      lon: "111.566549",
      lat: "24.403459",
    },
    451200: {
      cname: "河池市",
      pyname: "He Chi Shi",
      lon: "108.085399",
      lat: "24.69291",
    },
    451300: {
      cname: "来宾市",
      pyname: "Lai Bin Shi",
      lon: "109.222381",
      lat: "23.752101",
    },
    451400: {
      cname: "崇左市",
      pyname: "Chong Zuo Shi",
      lon: "107.36485",
      lat: "22.37895",
    },
  },
  460000: {
    460100: {
      cname: "海口市",
      pyname: "Hai Kou Shi",
      lon: "110.199889",
      lat: "20.044221",
    },
    460200: {
      cname: "三亚市",
      pyname: "San Ya Shi",
      lon: "109.51209",
      lat: "18.25248",
    },
    460300: {
      cname: "三沙市",
      pyname: "San Sha Shi",
      lon: "112.333559",
      lat: "16.832719",
    },
    469001: {
      cname: "五指山市",
      pyname: "Wu Zhi Shan Shi",
      lon: "109.51696",
      lat: "18.77515",
    },
    469002: {
      cname: "琼海市",
      pyname: "Qiong Hai Shi",
      lon: "110.47464",
      lat: "19.258381",
    },
    460400: {
      cname: "儋州市",
      pyname: "Dan Zhou Shi",
      lon: "109.58069",
      lat: "19.520931",
    },
    469005: {
      cname: "文昌市",
      pyname: "Wen Chang Shi",
      lon: "110.797741",
      lat: "19.54329",
    },
    469006: {
      cname: "万宁市",
      pyname: "Wan Ning Shi",
      lon: "110.38975",
      lat: "18.795319",
    },
    469007: {
      cname: "东方市",
      pyname: "Dong Fang Shi",
      lon: "108.65367",
      lat: "19.096139",
    },
    469021: {
      cname: "定安县",
      pyname: "Ding An Xian",
      lon: "110.359299",
      lat: "19.681211",
    },
    469022: {
      cname: "屯昌县",
      pyname: "Tun Chang Xian",
      lon: "110.10347",
      lat: "19.351821",
    },
    469023: {
      cname: "澄迈县",
      pyname: "Cheng Mai Xian",
      lon: "110.00487",
      lat: "19.73849",
    },
    469024: {
      cname: "临高县",
      pyname: "Lin Gao Xian",
      lon: "109.69077",
      lat: "19.912431",
    },
    469025: {
      cname: "白沙黎族自治县",
      pyname: "Bai Sha Li Zu Zi Zhi Xian",
      lon: "109.451519",
      lat: "19.22492",
    },
    469026: {
      cname: "昌江黎族自治县",
      pyname: "Chang Jiang Li Zu Zi Zhi Xian",
      lon: "109.05559",
      lat: "19.298279",
    },
    469027: {
      cname: "乐东黎族自治县",
      pyname: "Le Dong Li Zu Zi Zhi Xian",
      lon: "109.173609",
      lat: "18.749859",
    },
    469028: {
      cname: "陵水黎族自治县",
      pyname: "Ling Shui Li Zu Zi Zhi Xian",
      lon: "110.037201",
      lat: "18.505959",
    },
    469029: {
      cname: "保亭黎族苗族自治县",
      pyname: "Bao Ting Li Zu Miao Zu Zi Zhi Xian",
      lon: "109.702589",
      lat: "18.639049",
    },
    469030: {
      cname: "琼中黎族苗族自治县",
      pyname: "Qiong Zhong Li Zu Miao Zu Zi Zhi Xian",
      lon: "109.83839",
      lat: "19.03334",
    },
  },
  510000: {
    510100: {
      cname: "成都市",
      pyname: "Cheng Du Shi",
      lon: "104.064759",
      lat: "30.5702",
    },
    510300: {
      cname: "自贡市",
      pyname: "Zi Gong Shi",
      lon: "104.77844",
      lat: "29.339199",
    },
    510400: {
      cname: "攀枝花市",
      pyname: "Pan Zhi Hua Shi",
      lon: "101.71872",
      lat: "26.582281",
    },
    510500: {
      cname: "泸州市",
      pyname: "Lu Zhou Shi",
      lon: "105.442569",
      lat: "28.871699",
    },
    510600: {
      cname: "德阳市",
      pyname: "De Yang Shi",
      lon: "104.397899",
      lat: "31.12679",
    },
    510700: {
      cname: "绵阳市",
      pyname: "Mian Yang Shi",
      lon: "104.679019",
      lat: "31.467439",
    },
    510800: {
      cname: "广元市",
      pyname: "Guang Yuan Shi",
      lon: "105.84357",
      lat: "32.43549",
    },
    510900: {
      cname: "遂宁市",
      pyname: "Sui Ning Shi",
      lon: "105.59273",
      lat: "30.53286",
    },
    511000: {
      cname: "内江市",
      pyname: "Nei Jiang Shi",
      lon: "105.05844",
      lat: "29.58015",
    },
    511100: {
      cname: "乐山市",
      pyname: "Le Shan Shi",
      lon: "103.765391",
      lat: "29.552209",
    },
    511300: {
      cname: "南充市",
      pyname: "Nan Chong Shi",
      lon: "106.110729",
      lat: "30.837309",
    },
    511400: {
      cname: "眉山市",
      pyname: "Mei Shan Shi",
      lon: "103.84855",
      lat: "30.075521",
    },
    511500: {
      cname: "宜宾市",
      pyname: "Yi Bin Shi",
      lon: "104.641699",
      lat: "28.7513",
    },
    511600: {
      cname: "广安市",
      pyname: "Guang An Shi",
      lon: "106.63322",
      lat: "30.455959",
    },
    511700: {
      cname: "达州市",
      pyname: "Da Zhou Shi",
      lon: "107.46791",
      lat: "31.208639",
    },
    511800: {
      cname: "雅安市",
      pyname: "Ya An Shi",
      lon: "103.0398",
      lat: "30.01543",
    },
    511900: {
      cname: "巴中市",
      pyname: "Ba Zhong Shi",
      lon: "106.747331",
      lat: "31.867151",
    },
    512000: {
      cname: "资阳市",
      pyname: "Zi Yang Shi",
      lon: "104.62798",
      lat: "30.128589",
    },
    513200: {
      cname: "阿坝藏族羌族自治州",
      pyname: "A Ba Zang Zu Qiang Zu Zi Zhi Zhou",
      lon: "102.22477",
      lat: "31.899401",
    },
    513300: {
      cname: "甘孜藏族自治州",
      pyname: "Gan Zi Zang Zu Zi Zhi Zhou",
      lon: "101.962539",
      lat: "30.049321",
    },
    513400: {
      cname: "凉山彝族自治州",
      pyname: "Liang Shan Yi Zu Zi Zhi Zhou",
      lon: "102.267461",
      lat: "27.881641",
    },
  },
  520000: {
    520100: {
      cname: "贵阳市",
      pyname: "Gui Yang Shi",
      lon: "106.630241",
      lat: "26.64702",
    },
    520200: {
      cname: "六盘水市",
      pyname: "Liu Pan Shui Shi",
      lon: "104.83023",
      lat: "26.593359",
    },
    520300: {
      cname: "遵义市",
      pyname: "Zun Yi Shi",
      lon: "106.927231",
      lat: "27.725449",
    },
    520400: {
      cname: "安顺市",
      pyname: "An Shun Shi",
      lon: "105.9462",
      lat: "26.25367",
    },
    520500: {
      cname: "毕节市",
      pyname: "Bi Jie Shi",
      lon: "105.305039",
      lat: "27.29847",
    },
    520600: {
      cname: "铜仁市",
      pyname: "Tong Ren Shi",
      lon: "109.18099",
      lat: "27.69066",
    },
    522300: {
      cname: "黔西南布依族苗族自治州",
      pyname: "Qian Xi Nan Bu Yi Zu Miao Zu Zi Zhi Zhou",
      lon: "104.904371",
      lat: "25.089881",
    },
    522600: {
      cname: "黔东南苗族侗族自治州",
      pyname: "Qian Dong Nan Miao Zu Dong Zu Zi Zhi Zhou",
      lon: "107.98416",
      lat: "26.583639",
    },
    522700: {
      cname: "黔南布依族苗族自治州",
      pyname: "Qian Nan Bu Yi Zu Miao Zu Zi Zhi Zhou",
      lon: "107.522259",
      lat: "26.254271",
    },
  },
  530000: {
    530100: {
      cname: "昆明市",
      pyname: "Kun Ming Shi",
      lon: "102.83322",
      lat: "24.879659",
    },
    530300: {
      cname: "曲靖市",
      pyname: "Qu Jing Shi",
      lon: "103.79625",
      lat: "25.49002",
    },
    530400: {
      cname: "玉溪市",
      pyname: "Yu Xi Shi",
      lon: "102.546599",
      lat: "24.351929",
    },
    530500: {
      cname: "保山市",
      pyname: "Bao Shan Shi",
      lon: "99.16181",
      lat: "25.112051",
    },
    530600: {
      cname: "昭通市",
      pyname: "Zhao Tong Shi",
      lon: "103.716799",
      lat: "27.338171",
    },
    530700: {
      cname: "丽江市",
      pyname: "Li Jiang Shi",
      lon: "100.227101",
      lat: "26.85648",
    },
    530800: {
      cname: "普洱市",
      pyname: "Pu Er Shi",
      lon: "100.966239",
      lat: "22.825211",
    },
    530900: {
      cname: "临沧市",
      pyname: "Lin Cang Shi",
      lon: "100.088839",
      lat: "23.88426",
    },
    532300: {
      cname: "楚雄彝族自治州",
      pyname: "Chu Xiong Yi Zu Zi Zhi Zhou",
      lon: "101.527669",
      lat: "25.04495",
    },
    532500: {
      cname: "红河哈尼族彝族自治州",
      pyname: "Hong He Ha Ni Zu Yi Zu Zi Zhi Zhou",
      lon: "103.375599",
      lat: "23.364221",
    },
    532600: {
      cname: "文山壮族苗族自治州",
      pyname: "Wen Shan Zhuang Zu Miao Zu Zi Zhi Zhou",
      lon: "104.215039",
      lat: "23.39849",
    },
    532800: {
      cname: "西双版纳傣族自治州",
      pyname: "Xi Shuang Ban Na Dai Zu Zi Zhi Zhou",
      lon: "100.797389",
      lat: "22.007489",
    },
    532900: {
      cname: "大理白族自治州",
      pyname: "Da Li Bai Zu Zi Zhi Zhou",
      lon: "100.267641",
      lat: "25.60648",
    },
    533100: {
      cname: "德宏傣族景颇族自治州",
      pyname: "De Hong Dai Zu Jing Po Zu Zi Zhi Zhou",
      lon: "98.584859",
      lat: "24.43232",
    },
    533300: {
      cname: "怒江傈僳族自治州",
      pyname: "Nu Jiang Li Su Zu Zi Zhi Zhou",
      lon: "98.856699",
      lat: "25.81763",
    },
    533400: {
      cname: "迪庆藏族自治州",
      pyname: "Di Qing Zang Zu Zi Zhi Zhou",
      lon: "99.703049",
      lat: "27.81908",
    },
  },
  540000: {
    540100: {
      cname: "拉萨市",
      pyname: "La Sa Shi",
      lon: "91.114529",
      lat: "29.644141",
    },
    540300: {
      cname: "昌都市",
      pyname: "Chang Du Di Qu",
      lon: "97.17225",
      lat: "31.140729",
    },
    540500: {
      cname: "山南市",
      pyname: "Shan Nan Di Qu",
      lon: "91.773129",
      lat: "29.237051",
    },
    540200: {
      cname: "日喀则市",
      pyname: "Ri Ka Ze Di Qu",
      lon: "88.881369",
      lat: "29.266849",
    },
    540600: {
      cname: "那曲市",
      pyname: "Na Qu Di Qu",
      lon: "92.051361",
      lat: "31.476139",
    },
    542500: {
      cname: "阿里地区",
      pyname: "A Li Di Qu",
      lon: "81.15763",
      lat: "30.40199",
    },
    540400: {
      cname: "林芝市",
      pyname: "Lin Zhi Di Qu",
      lon: "94.361549",
      lat: "29.64895",
    },
  },
  610000: {
    610100: {
      cname: "西安市",
      pyname: "Xi An Shi",
      lon: "108.939839",
      lat: "34.34127",
    },
    610200: {
      cname: "铜川市",
      pyname: "Tong Chuan Shi",
      lon: "108.94515",
      lat: "34.89673",
    },
    610300: {
      cname: "宝鸡市",
      pyname: "Bao Ji Shi",
      lon: "107.23732",
      lat: "34.36194",
    },
    610400: {
      cname: "咸阳市",
      pyname: "Xian Yang Shi",
      lon: "108.70929",
      lat: "34.329321",
    },
    610500: {
      cname: "渭南市",
      pyname: "Wei Nan Shi",
      lon: "109.51015",
      lat: "34.49997",
    },
    610600: {
      cname: "延安市",
      pyname: "Yan An Shi",
      lon: "109.489781",
      lat: "36.585291",
    },
    610700: {
      cname: "汉中市",
      pyname: "Han Zhong Shi",
      lon: "107.02377",
      lat: "33.067611",
    },
    610800: {
      cname: "榆林市",
      pyname: "Yu Lin Shi",
      lon: "109.734579",
      lat: "38.2852",
    },
    610900: {
      cname: "安康市",
      pyname: "An Kang Shi",
      lon: "109.029321",
      lat: "32.684859",
    },
    611000: {
      cname: "商洛市",
      pyname: "Shang Luo Shi",
      lon: "109.94041",
      lat: "33.87036",
    },
  },
  620000: {
    620100: {
      cname: "兰州市",
      pyname: "Lan Zhou Shi",
      lon: "103.834171",
      lat: "36.06138",
    },
    620200: {
      cname: "嘉峪关市",
      pyname: "Jia Yu Guan Shi",
      lon: "98.290111",
      lat: "39.77201",
    },
    620300: {
      cname: "金昌市",
      pyname: "Jin Chang Shi",
      lon: "102.187589",
      lat: "38.520061",
    },
    620400: {
      cname: "白银市",
      pyname: "Bai Yin Shi",
      lon: "104.13773",
      lat: "36.544701",
    },
    620500: {
      cname: "天水市",
      pyname: "Tian Shui Shi",
      lon: "105.724859",
      lat: "34.580851",
    },
    620600: {
      cname: "武威市",
      pyname: "Wu Wei Shi",
      lon: "102.637971",
      lat: "37.928201",
    },
    620700: {
      cname: "张掖市",
      pyname: "Zhang Ye Shi",
      lon: "100.449809",
      lat: "38.92592",
    },
    620800: {
      cname: "平凉市",
      pyname: "Ping Liang Shi",
      lon: "106.665299",
      lat: "35.54303",
    },
    620900: {
      cname: "酒泉市",
      pyname: "Jiu Quan Shi",
      lon: "98.493941",
      lat: "39.73255",
    },
    621000: {
      cname: "庆阳市",
      pyname: "Qing Yang Shi",
      lon: "107.642921",
      lat: "35.709781",
    },
    621100: {
      cname: "定西市",
      pyname: "Ding Xi Shi",
      lon: "104.625241",
      lat: "35.581131",
    },
    621200: {
      cname: "陇南市",
      pyname: "Long Nan Shi",
      lon: "104.92166",
      lat: "33.401",
    },
    622900: {
      cname: "临夏回族自治州",
      pyname: "Lin Xia Hui Zu Zi Zhi Zhou",
      lon: "103.210909",
      lat: "35.60122",
    },
    623000: {
      cname: "甘南藏族自治州",
      pyname: "Gan Nan Zang Zu Zi Zhi Zhou",
      lon: "102.91102",
      lat: "34.98326",
    },
  },
  630000: {
    630100: {
      cname: "西宁市",
      pyname: "Xi Ning Shi",
      lon: "101.777819",
      lat: "36.617289",
    },
    630200: {
      cname: "海东市",
      pyname: "Hai Dong Shi",
      lon: "102.40173",
      lat: "36.48209",
    },
    632200: {
      cname: "海北藏族自治州",
      pyname: "Hai Bei Zang Zu Zi Zhi Zhou",
      lon: "100.900959",
      lat: "36.95454",
    },
    632300: {
      cname: "黄南藏族自治州",
      pyname: "Huang Nan Zang Zu Zi Zhi Zhou",
      lon: "102.015069",
      lat: "35.519911",
    },
    632500: {
      cname: "海南藏族自治州",
      pyname: "Hai Nan Zang Zu Zi Zhi Zhou",
      lon: "100.620211",
      lat: "36.28643",
    },
    632600: {
      cname: "果洛藏族自治州",
      pyname: "Guo Luo Zang Zu Zi Zhi Zhou",
      lon: "100.24475",
      lat: "34.471411",
    },
    632700: {
      cname: "玉树藏族自治州",
      pyname: "Yu Shu Zang Zu Zi Zhi Zhou",
      lon: "97.0065",
      lat: "33.00528",
    },
    632800: {
      cname: "海西蒙古族藏族自治州",
      pyname: "Hai Xi Meng Gu Zu Zang Zu Zi Zhi Zhou",
      lon: "97.37122",
      lat: "37.377101",
    },
  },
  640000: {
    640100: {
      cname: "银川市",
      pyname: "Yin Chuan Shi",
      lon: "106.23248",
      lat: "38.486441",
    },
    640200: {
      cname: "石嘴山市",
      pyname: "Shi Zui Shan Shi",
      lon: "106.38418",
      lat: "38.984099",
    },
    640300: {
      cname: "吴忠市",
      pyname: "Wu Zhong Shi",
      lon: "106.198789",
      lat: "37.99755",
    },
    640400: {
      cname: "固原市",
      pyname: "Gu Yuan Shi",
      lon: "106.242589",
      lat: "36.015801",
    },
    640500: {
      cname: "中卫市",
      pyname: "Zhong Wei Shi",
      lon: "105.19676",
      lat: "37.50026",
    },
  },
  650000: {
    650100: {
      cname: "乌鲁木齐市",
      pyname: "Wu Lu Mu Qi Shi",
      lon: "87.616879",
      lat: "43.82663",
    },
    650200: {
      cname: "克拉玛依市",
      pyname: "Ke La Ma Yi Shi",
      lon: "84.889271",
      lat: "45.579989",
    },
    650400: {
      cname: "吐鲁番市",
      pyname: "Tu Lu Fan Di Qu",
      lon: "89.18954",
      lat: "42.9513",
    },
    650500: {
      cname: "哈密市",
      pyname: "Ha Mi Di Qu",
      lon: "93.516261",
      lat: "42.818739",
    },
    652300: {
      cname: "昌吉回族自治州",
      pyname: "Chang Ji Hui Zu Zi Zhi Zhou",
      lon: "87.30822",
      lat: "44.01117",
    },
    652700: {
      cname: "博尔塔拉蒙古自治州",
      pyname: "Bo Er Ta La Meng Gu Zi Zhi Zhou",
      lon: "82.066649",
      lat: "44.90597",
    },
    652800: {
      cname: "巴音郭楞蒙古自治州",
      pyname: "Ba Yin Guo Leng Meng Gu Zi Zhi Zhou",
      lon: "86.145169",
      lat: "41.764041",
    },
    652900: {
      cname: "阿克苏地区",
      pyname: "A Ke Su Di Qu",
      lon: "80.26008",
      lat: "41.16842",
    },
    653000: {
      cname: "克孜勒苏柯尔克孜自治州",
      pyname: "Ke Zi Le Su Ke Er Ke Zi Zi Zhi Zhou",
      lon: "76.16661",
      lat: "39.715299",
    },
    653100: {
      cname: "喀什地区",
      pyname: "Ka Shi Di Qu",
      lon: "75.989759",
      lat: "39.470421",
    },
    653200: {
      cname: "和田地区",
      pyname: "He Tian Di Qu",
      lon: "79.92247",
      lat: "37.11431",
    },
    654000: {
      cname: "伊犁哈萨克自治州",
      pyname: "Yi Li Ha Sa Ke Zi Zhi Zhou",
      lon: "81.32416",
      lat: "43.91689",
    },
    654200: {
      cname: "塔城地区",
      pyname: "Ta Cheng Di Qu",
      lon: "82.98046",
      lat: "46.745319",
    },
    654300: {
      cname: "阿勒泰地区",
      pyname: "A Le Tai Di Qu",
      lon: "88.14023",
      lat: "47.84564",
    },
    659001: {
      cname: "石河子市",
      pyname: "Shi He Zi Shi",
      lon: "86.07893",
      lat: "44.30653",
    },
    659002: {
      cname: "阿拉尔市",
      pyname: "A La Er Shi",
      lon: "81.280671",
      lat: "40.54798",
    },
    659003: {
      cname: "图木舒克市",
      pyname: "Tu Mu Shu Ke Shi",
      lon: "79.069019",
      lat: "39.86495",
    },
    659004: {
      cname: "五家渠市",
      pyname: "Wu Jia Qu Shi",
      lon: "87.540169",
      lat: "44.16799",
    },
    659006: {
      cname: "铁门关市",
      pyname: "Tie Men Guan Shi",
      lon: "85.670291",
      lat: "41.862997",
    },
    659005: {
      cname: "北屯市",
      pyname: "Bei Tun Shi",
      lon: "87.834419",
      lat: "47.326733",
    },
    659007: {
      cname: "双河市",
      pyname: "Shuang He Shi",
      lon: "82.35365587",
      lat: "44.84052409",
    },
    659008: {
      cname: "可克达拉市",
      pyname: "Ke Ke Da La Shi",
      lon: "80.994153",
      lat: "43.940381",
    },
    659009: {
      cname: "昆玉市",
      pyname: "Kun Yu Shi",
      lon: "79.270193",
      lat: "37.215372",
    },
    659010: {
      cname: "胡杨河市",
      pyname: "Hu Yang He Shi",
      lon: "84.827592",
      lat: "44.692894",
    },
  },
};
export default Citys;
// 热门城市
export const hot = [
  "110000",
  "440100",
  "310000",
  "440300",
  "330100",
  "510100",
  "340100",
  "320100",
  "120000",
  "440600",
  "330200",
  "370200",
  "330300",
  "420100",
  "500000",
  "350500",
  "320500",
  "360100",
  "350200",
  "440400",
];
// 直辖市及港澳台
export const municipality = [
  "110000",
  "120000",
  "310000",
  "500000",
  "810000",
  "820000",
  "710000",
];
// 省拼音快捷索引
export const provLetters = [
  "A",
  "F",
  "G",
  "H",
  "J",
  "L",
  "N",
  "Q",
  "S",
  "X",
  "Y",
  "Z",
];
// 所有的拼音索引
export const allLetters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
