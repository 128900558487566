<template>
  <div class="city-tool" :class="cityTheme">
    <p
      class="city-tool-location"
      @click.stop="isOpenCitySelect = !isOpenCitySelect"
    >
      <span class="city text-ellipsis">{{ selectCityName || "选择城市" }}</span>
      <Icon custom="icon-arrow2 middle-box"></Icon>
    </p>

    <City
      class="city-select-wrap"
      :curCity="selectCityName"
      ref="city"
      v-if="isOpenCitySelect"
      @close="isOpenCitySelect = false"
      @select="doSelectCity"
    >
    </City>
  </div>
</template>

<script>
import City from "./City.vue";
export default {
  props: ["theme", "cityName"],
  data() {
    return {
      selectCityName: "",
      isOpenCitySelect: false,
    };
  },
  computed: {
    cityTheme() {
      let obj = {
        white: "white-style",
      };
      return (this.theme && obj[this.theme]) || "";
    },
  },
  watch: {
    cityName(newval) {
      if (newval === this.selectCityName) {
        return;
      }
      this.selectCityName = newval;
    },
  },
  methods: {
    doSelectCity(city) {
      this.selectCityName = city.cname;
      this.$emit("select", city);
      this.isOpenCitySelect = false;
    },
  },
  components: {
    City,
  },
};
</script>

<style lang="less">
.city-tool {
  height: 32px;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  z-index: 99;
}
.city-tool {
  border: none;

  .city-tool-location {
    width: 100%;
    height: 32px;
    font-size: 12px;
    line-height: 32px;
    padding: 0 24px 0 12px;
    border-radius: 4px;
    background: #1e2e49;

    .location-icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      margin-top: 8px;
    }

    .ivu-icon {
      // right: -16px;
      color: #7a838b;
      width: 8px;
      height: 6px;
      vertical-align: middle;
      margin-top: 1px;
      margin-left: 2px;
    }
  }

  .ivu-icon {
    color: rgba(255, 255, 255, 0.5);
    font-size: 20px;
  }

  .city {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    min-width: 60px;
    max-width: 80px;
    height: 32px;
  }
}
.city-select-wrap {
  position: absolute;
  top: 0px;
  right: 100px;
  width: 302px;
  z-index: 99;
  border-radius: 6px;
}
.white-style.city-tool {
  .city-tool-location {
    background: #fff;
  }
  .modal-bg {
    background-color: #fff;
  }
  .city {
    color: #333;
  }
}
</style>
