var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "city-tool", class: _vm.cityTheme },
    [
      _c(
        "p",
        {
          staticClass: "city-tool-location",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              _vm.isOpenCitySelect = !_vm.isOpenCitySelect
            },
          },
        },
        [
          _c("span", { staticClass: "city text-ellipsis" }, [
            _vm._v(_vm._s(_vm.selectCityName || "选择城市")),
          ]),
          _c("Icon", { attrs: { custom: "icon-arrow2 middle-box" } }),
        ],
        1
      ),
      _vm.isOpenCitySelect
        ? _c("City", {
            ref: "city",
            staticClass: "city-select-wrap",
            attrs: { curCity: _vm.selectCityName },
            on: {
              close: function ($event) {
                _vm.isOpenCitySelect = false
              },
              select: _vm.doSelectCity,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }