<template>
  <div class="legends-wrap">
    <div v-if="title" class="legend-title">
      {{ title }}
      <i-switch
        size="small"
        class="legend-switch"
        v-model="isShowAll"
        @on-change="changeAll"
      ></i-switch>
    </div>
    <div class="legends-list">
      <ul>
        <li
          v-for="(item, index) in legends"
          :key="'legend_' + index"
          :title="legendTitleObj[index]"
        >
          <i
            class="legend-icon"
            :style="{ 'background-color': item.color }"
          ></i>
          <span class="legend-name">{{ legendTitleObj[index] }}</span>
          <i-switch
            class="legend-switch"
            size="small"
            :value="showObj[index]"
            @on-change="changeShow(index, $event)"
          ></i-switch>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["legendsObj", "legendsNums", "isShowNum"],
  data() {
    return {
      isShowAll: true,
      showObj: {},
      isInit: false,
    };
  },
  computed: {
    title() {
      return this.legendsObj.title;
    },
    legends() {
      return this.legendsObj.list;
    },
    legendTitleObj() {
      let legends = this.legends;
      let obj = {};
      for (let i = 0, n = legends.length; i < n; i++) {
        let title = legends[i].title;
        if (this.isShowNum) {
          let num = (this.legendsNums && this.legendsNums[i]) || 0;
          title += `(${num})`;
        }
        obj[i] = title;
      }
      return obj;
    },
  },
  methods: {
    initData() {
      let obj = {};
      for (let i = 0, n = this.legends.length; i < n; i++) {
        let legend = this.legends[i];
        if (legend.show !== undefined) {
          obj[i] = !!legend.show;
        } else {
          obj[i] = true;
        }
      }
      this.showObj = Object.assign({}, this.showObj, obj);
      this.isInit = true;
    },
    changeShow(index, isShow) {
      this.showObj[index] = isShow;
      this.$set(this.showObj, index, this.showObj[index]);
      this.checkAllShow();
      this.$emit("changeShow", index, isShow);
    },
    changeAll(isShow) {
      this.isShowAll = !!isShow;
      for (let i = 0, n = this.legends.length; i < n; i++) {
        if (this.showObj[i] !== isShow) {
          this.showObj[i] = this.isShowAll;
          this.$set(this.showObj, i, this.showObj[i]);
          this.$emit("changeShow", i, isShow);
        }
      }
    },
    checkAllShow() {
      let isAllShow = true;
      for (let i = 0, n = this.legends.length; i < n; i++) {
        if (!this.showObj[i]) {
          isAllShow = false;
          break;
        }
      }
      this.isShowAll = isAllShow;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="less">
@import url("style/config.less");
.legends-wrap {
  position: absolute;
  left: 12px;
  bottom: 20px;
  width: 160px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 99;
  .ivu-switch-checked {
    border-color: @focusColor;
    background-color: @focusColor;
  }
  .legend-title {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    padding: 0 16px;
    vertical-align: middle;
    color: #000;
    background-color: hsla(247, 100%, 61%, 0.04);
    position: relative;
  }
  .legend-switch {
    vertical-align: middle;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .legends-list {
    padding: 4px 0 10px 0;
    color: #000;
    .legend-icon {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      margin-right: 6px;
      overflow: hidden;
      display: inline-block;
    }
    .legend-name {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 14px - 28px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    ul {
      li {
        font-size: 14px;
        line-height: 32px;
        padding: 0 16px;
        color: hsla(0, 0%, 0%, 0.8);
        position: relative;
      }
    }
  }
}
</style>
